// general
.main .container-fluid {
  padding: 0;
}

// post stats modal
.switch {
  height: 22px;
  width: 36px;
}

.switch-slider::before {
  height: 16px;
  width: 16px;
}

// .react-calendar__tile--active {
//   background: $theme-color !important;
// }

// .react-calendar__navigation {
//   background: #f5f8fd;
//   margin-bottom: 0 !important;
// }

// .react-daterange-picker__calendar {
//   margin: 5px auto;
//   box-shadow: 0 0.175em 0.5em rgba(2, 8, 20, 0.1),
//     0 0.085em 0.175em rgba(2, 8, 20, 0.08);
//   border: 1px solid #f5f5f5;
//   width: 330px !important;
// }

// .react-calendar__month-view__weekdays {
//   background: #f5f8fd;
//   color: $mediumGray;
// }

// .react-calendar__navigation button {
//   color: $mediumGray;
// }

// .react-calendar {
//   border: 0 !important;
//   width: 330px !important;
// }

.fab-container {
  bottom: 38px !important;
  right: 1.2vw !important;
  z-index: 99;
  display: block;
}

.fab-container button {
  color: $theme-color !important;
}

.fab-item:not(:last-child) {
  background: $theme-color !important;
  color: #fff !important;
  letter-spacing: 0.5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.fab-container:hover .fab-item:not(:last-child) {
  margin: 0 auto 12px !important;
}

.fab-item {
  margin: 0 auto !important;
}

.fab-item[tooltip]:before {
  font-weight: normal;
}

@media all and (max-width: 575.98px) {
  .fab-container {
    bottom: 47px !important;
    right: 0.5vw !important;
  }
}
