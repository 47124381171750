:root {
  --primary-color: #5f42aa;
  --primary-color-rgb: 95, 66, 170; // rgba color of #5f42aa (primary-color)
}

$theme-color: var(--primary-color);
$grey: #333333;
$mediumGray: #4f4f4f;
$lightGray: #828282;
$white: #fff;
$black: #000;
$error: #f16667;
$secondaryBtnColor: #8f8f8f;
$inputBorder: #eff0f6;

:export {
  themeColor: $theme-color;
}

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  color: $grey;
  background: $white;
  letter-spacing: 0.3px;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

// iframe {
//   display: none;
// }

@mixin ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

.form-group {
  margin-bottom: 20px;
}

.btn {
  outline: 0 !important;
  letter-spacing: 0.3px;
  box-shadow: none !important;
  cursor: pointer;
}

.themeBtn {
  max-width: 260px;
  font-weight: 600;
  width: 100%;
  background: $theme-color !important;
  font-size: 16px;
  color: $white !important;
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  border-radius: 30px;
  margin: 10px 0;
  height: 42px;
  line-height: 42px;
  padding: 0 6px;
  @include ripple;
}

select {
  background: url("data:image/svg+xml;utf8,<svg fill='dark gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 9px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

video {
  background-color: #111;
}

// header & side menu css
.app-header {
  flex: 0 0 48px;
  height: 48px;
  border-bottom: 1px solid #f0f0f0;

  .nav-item {
    min-width: unset;

    .nav-link {
      font-size: 14px;
      color: #999;
      height: 47px;
      align-items: center;
      display: flex;
      border-bottom: 3px solid transparent;
      margin-top: 3px;
      position: relative;
    }
  }

  .dropdown-item {
    min-width: unset;
  }

  .navbar-toggler {
    outline: 0 !important;
  }

  .nav-item .nav-link .badge {
    border-radius: 3px;
    padding: 2px 5px 1px;
    font-size: 12px;
    background-color: $theme-color;
    color: $white;
    right: -10px;
    top: 4px;
    margin-top: unset;
    left: unset;
  }
}

.navbar-nav {
  .nav-link {
    &.active,
    &.active:focus,
    &.active:hover {
      color: $theme-color;
      border-bottom: 3px solid $theme-color;
      outline: 0;
    }

    &:focus,
    &:hover {
      color: $grey;
    }
  }
}

.app-footer .nav-item .nav-link {
  position: relative;
  .badge {
    border-radius: 3px;
    padding: 2px 4px 1px;
    font-size: 12px;
    background-color: $theme-color;
    color: $white;
    right: -10px;
    top: 2px;
    position: absolute;
  }
}

.poweredBy a {
  color: #31c5c7;
  text-decoration: none !important;
  display: inline-block;
  margin-left: 5px;
}

.app-footer {
  flex: 0 0 50px;
  color: #999;
  font-size: 13px;
  border-top: 2px solid #f0f0f0;
  background-color: $white;
  justify-content: space-between;
}

.app-header .navbar-brand {
  height: 50px;
  width: 150px;
  img {
    // width: 85%;
    height: 85%;
    object-fit: contain;
    margin-top: 3px;
  }
}

.loginWrapper {
  width: 100%;
  max-width: 480px;
  margin-top: auto;
  margin-bottom: auto;

  .form-group {
    margin-top: 15px;
  }

  h4 {
    margin-bottom: 25px;
    text-align: center;
    font-size: 20px;
    color: $grey;
    font-weight: 600;
  }
}

.backToLogin {
  text-decoration: none !important;
  display: block;
  text-align: center;
  max-width: 140px;
  margin: 20px auto 0;
  color: $lightGray !important;
}

.signUpWrap {
  border: 0;
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.genderWrapper {
  min-height: 65px;
  margin-bottom: 1rem;
}

.genderTxt {
  color: $grey;
  font-size: 15px;
}

.genderTxt i {
  color: #999;
}

.fab-container {
  display: none;
}

.agreeTermsTxt {
  font-size: 14px;
  color: $grey;
  margin-left: 3px;
  cursor: pointer;
}

.agreeTermsTxt a {
  color: $theme-color;
}

.orTxt {
  width: 100%;
  max-width: 180px;
  height: 1px;
  background: #e0e0e0;
  display: block;
  margin: 35px auto;
  position: relative;

  span {
    color: #999;
    font-size: 14px;
    background-color: $white;
    padding: 2px 10px;
    letter-spacing: 0.5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.socialLoginBtn {
  border: 1px solid #b6b6b6 !important;
  height: 46px;
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
  color: $grey !important;
  background: $white !important;
  border-radius: 30px;
  margin: 0 auto 15px;
  line-height: 44px;
  @include ripple;
  font-size: 14px;

  img {
    height: 20px;
    vertical-align: -5px;
    margin-left: 6px;
  }
}

.socialLoginWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.socialBtnIconOnly {
  border-radius: 10px;
  padding: 0 15px;
  background-color: $white !important;
  border: 1px solid #e0e0e0;
  height: 46px;
  margin: 0 10px;

  img {
    height: 24px;
  }
}

.haveAccountTxt {
  font-size: 14px;
  color: $grey;
  text-align: center;

  span {
    color: $theme-color;
    cursor: pointer;
  }
}

.eyeIcon,
.spinnerLogin {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $grey;
  font-size: 16px;

  .fa {
    cursor: pointer;
  }
}

.remainAnonymous {
  display: block;
  margin: 0 auto;
  color: $lightGray !important;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none !important;
}

.addPicTxt {
  text-align: center;
  padding: 0 20px;
  margin-top: 28px;

  h5 {
    font-size: 18px;
    color: $grey;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: $lightGray;
  }
}

.welcomeModalTxt {
  text-align: center;
  margin-top: 12px;

  h4 {
    margin-bottom: 0;
    font-size: 28px;
    color: $grey;
    font-weight: 700;
  }

  p {
    margin-top: 22px;
    margin-bottom: 28px;
    color: $mediumGray;
    line-height: 1.6;
    font-size: 16px;
  }
}

// login
.authFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
  width: 100%;
  position: relative;
}

.installPWA {
  background-color: $theme-color !important;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-weight: 600;
  padding: 0 5px;
  border: 0;
  color: $white !important;
  border-radius: 30px;
  width: 175px;
  box-shadow: none !important;
  position: fixed;
  top: 25px;
  right: 25px;
}

.projectLogo {
  width: 220px;
  cursor: pointer;
  display: block;
  margin: 25px auto 0;
}

.forgotPassword {
  float: right;
  padding: 0;
  font-size: 14px;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  color: $theme-color !important;
  box-shadow: none !important;
  font-weight: 500;
}

.loginBtn {
  border: 1px solid $theme-color !important;
  height: 50px;
  max-width: 300px;
  font-size: 14px;
  margin: 30px auto 10px;
  line-height: 48px;
  display: block;
}

.registerBtn {
  color: #999 !important;
  font-size: 14px;
  padding: 6px 0;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  display: block;
  margin: 6px auto 0;
  @include ripple;

  span {
    color: $theme-color;
    text-decoration: underline;
  }
}

.authPgFormWrap {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px 25px;
  margin-top: 30px;
  margin-bottom: 45px;

  h4 {
    font-size: 20px;
    color: $mediumGray;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
  }

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500;
  }
}

.loginFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $lightGray;
  width: 100%;

  * {
    font-size: 13px;
  }

  .socialLinksFooter-Login {
    display: flex;
  }

  .socialLinksFooter a {
    color: $lightGray !important;
  }
}

.socialLinksFooter {
  display: flex;

  a {
    text-decoration: none !important;
    display: inline-block;
  }

  a + a {
    margin-left: 10px;
  }
}

.msgWrapWeb {
  display: flex;
  width: 100%;
  height: calc(100vh - 95px);
  background-color: $white;
  border: 1px solid #e5e5e5;
  border-top: 0;

  .chatListWeb {
    border-right: 1px solid #e5e5e5;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;

    .pgTitle {
      border-bottom: 1px solid #e5e5e5;
      margin: 15px 0;
      padding: 0 15px 15px;

      h2 {
        font-size: 20px;
      }
    }

    .chatList {
      height: calc(100vh - 197px);
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: #e0e0e0 #f5f5f5;
      scrollbar-width: thin;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
        box-shadow: inset 0 0 6px rgba($black, 0.1);
        border-radius: 0;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
        box-shadow: inset 0 0 6px rgba($black, 0.1);
        background-color: #e0e0e0;
      }
    }
  }

  .conversationWeb {
    flex: 1;
    padding-left: 8px;

    .customBackBtn {
      display: none;
    }
  }

  .selectChatUserWeb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .fa {
      font-size: 100px;
      color: #e0e0e0;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #ccc;
    }
  }
}

// general
.customPgHeight {
  min-height: unset;
  height: calc(100vh - 98px);
  overflow: auto;
  background: #fafafa;
}

.customPgHeightMsg {
  height: calc(100vh - 170px);
}

label {
  color: $mediumGray !important;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding-top: 0 !important;
  line-height: unset !important;
}

.pgTitle {
  margin: 30px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: $grey;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }
}

.customBackBtn {
  padding: 0;
  font-size: 20px;
  color: $grey !important;
  margin-right: 5px;
  background: transparent !important;
  border: 1px solid transparent !important;
  @include ripple;
  height: 26px;
  line-height: 26px;
  width: 26px;
  transition: all 0.3s;
  border-radius: 6px;

  &:hover,
  &:focus {
    background: rgba($black, 0.08) !important;
    transition: all 0.3s;
  }

  i {
    margin-left: -1px;
  }
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  margin: 45px 0 15px;

  h4 {
    font-size: 22px;
    color: $grey;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.form-control,
.multiSelectWrap .select__control {
  color: $lightGray !important;
  padding: 6px 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border: 1px solid $inputBorder;
  background-color: $inputBorder !important;
  border-radius: 8px;
  letter-spacing: 0.3px;
  height: 46px;

  &:focus {
    box-shadow: none !important;
    border-color: $theme-color;
  }
}

.multiSelectWrap .select__control {
  min-height: 46px;
  height: unset;
  padding: 0;

  &:hover {
    border-color: transparent;
  }

  .select__multi-value {
    background-color: $white;
  }

  .select__multi-value__label {
    padding-bottom: 2px;
  }

  .select__multi-value__remove {
    svg {
      fill: $error;
    }
  }
}

select.form-control {
  padding-right: 19px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px !important;
}

.noContentFound {
  font-size: 14px;
  text-align: center;
  margin: 25px 0;
  font-weight: 500;
  color: $lightGray;
  line-height: 1.4;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.notificationOptions {
  margin: 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .custom-control {
    height: 15px;
    min-height: 1.11rem;
    cursor: pointer;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0.05rem;
    left: -1rem;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: 400;
  }
}

.custom-control-label::before {
  border-color: $mediumGray;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $theme-color;
  background-color: $theme-color;
  color: $theme-color;
}

.custom-control-input {
  &:checked {
    & ~ .custom-control-label::before,
    & ~ .custom-control-label::after {
      top: 2px;
    }
  }
}

.custom-control-label {
  &:before {
    top: 2px;
  }
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border-color: rgba($black, 0.5);
}

.custom-control-input:focus:checked ~ .custom-control-label::before {
  border-color: $theme-color;
}

.custom-control-input:checked + label {
  color: $theme-color;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #999;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e4e7ea;
  border: #b1b8bf solid 1px;
}

.custom-control-label {
  font-size: 14px;
  color: $grey;
}

.custom-control {
  input,
  label {
    cursor: pointer;
  }
}

.btn-instagram {
  background-color: #e1306c !important;
  border-color: #e1306c !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 48, 108, 0.5) !important;
  }
}

.btn-tiktok {
  background-color: $black !important;
  border-color: $black !important;
  color: $white !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($black, 0.5) !important;
  }
}

.tiktok {
  width: 11px;
}

.btn-brand {
  @include ripple;
  line-height: 1;
}

.customPopoverBtn,
.customDropdown .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: $mediumGray !important;
  padding: 0.1rem 0.5rem;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.customPopoverBtn {
  padding: 0 14px;
  height: 30px;

  img {
    height: 24px;
    vertical-align: -7px;
  }
}

.customPopover {
  .popover {
    max-width: unset;
    border: 0 !important;
    box-shadow: 0 1px 4px 0 rgba($black, 0.1);

    .arrow::before {
      border-bottom-color: rgba($black, 0.08);
    }
  }

  .popover-body {
    min-width: 125px;
    padding: 10px;
  }

  .radioWrap:not(:last-child) {
    margin-bottom: 8px;
  }

  .custom-control-label {
    font-weight: 400;
    cursor: pointer;
    font-size: 13px;
  }
}

.searchIcon {
  font-size: 13px;
  color: #999;
  top: 11px;
  left: 9px;
  position: absolute;
  z-index: 99;
}

img {
  font-size: 9px;
}

.themeColor {
  color: $theme-color !important;
}

.cursorPointer {
  cursor: pointer;
}

// modal
.modal-content {
  border: 0;
  box-shadow: none;
  overflow: auto;
  border-radius: 20px;
}

.modal-header,
.modal-footer {
  padding: 16px 15px 12px;
  border-color: rgba($black, 0.05);
  background-color: $white;
}

.modal-footer {
  padding: 9px 15px;
}

.modal-title,
.customModalHeader {
  font-size: 16px;
  color: $grey;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

.modal-body {
  color: $mediumGray;
  padding: 15px;
}

.modal-header .close {
  padding: 0.3rem 0.6rem;
  margin: -0.2rem -0.6rem -1rem auto;
  font-size: 26px;
  outline: 0 !important;
  line-height: 18px;
  color: #f16667;
  opacity: 1 !important;
}

.modalBtnSave,
.modalBtnCancel {
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  border: 1px solid $theme-color !important;
  padding: 0 15px;
  @include ripple;
  border-radius: 25px;
  height: 36px;
  line-height: 34px;
  font-weight: 600;
  margin: 0 !important;
}

.modalBtnCancel {
  background-color: $white !important;
  color: #cccccc !important;
  border: 1px solid #cccccc !important;
  margin-right: 10px !important;
}

.modalBtnYes,
.modalBtnNo {
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid $theme-color !important;
  padding: 0 20px;
  @include ripple;
  border-radius: 25px;
  height: 36px;
  line-height: 34px;
  margin-top: 0;
  margin-bottom: 0;
}

.modalBtnNo {
  background-color: #ccc !important;
  border: 1px solid #ccc !important;
  margin-right: 10px !important;
}

.customModalHeader {
  line-height: 1.5;
  margin-bottom: 20px;
}

.areYouSureTxt {
  font-size: 14px;
  color: $grey;
  text-align: center;
  margin: 15px auto 5px;
}

// my subscriptions
.subscriptionsCard {
  border-radius: 16px;
  border: 0;
  margin-bottom: 20px;
  position: relative;

  .card-body {
    padding: 13px 10px 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .card-footer {
    padding: 12px 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 0;
    background-color: $white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.img60by60 {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba($black, 0.05);
  cursor: pointer;
}

.img100by100Wrap {
  border: 1px solid rgba($black, 0.05);
  width: 102px;
  height: 102px;
  margin-right: 10px;
  position: relative;
  border-radius: 50%;
}

.img100by100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.subscriberPriceTier {
  display: flex;
  align-items: center;
  margin-top: 8px;

  img,
  svg {
    height: 24px;
    margin-right: 5px;
    width: 24px;
    fill: $theme-color;
    margin-top: -2px;
  }

  .premiumTier {
    margin-top: -4px;
  }

  p {
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    color: $grey;
  }

  div {
    margin-right: 20px;
  }

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.influencerName {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 8px;
  color: $grey;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 5px;
    font-weight: 600;
  }

  a {
    color: $theme-color !important;
    font-size: 14px;
    text-decoration: none !important;
  }
}

.introInSubscription {
  color: $mediumGray;
  margin-top: 15px;
  font-weight: 300;
}

p.introInSubscription {
  margin-bottom: 7px;
}

.seeBundles {
  color: $mediumGray !important;
  font-size: 13px;
  margin-left: auto;
  text-transform: uppercase;
  text-decoration: none !important;
  padding: 0 2px;
  height: 34px;
  line-height: 34px;
}

.subscriptionDateTxt {
  font-size: 12px;
  margin-bottom: 6px;
  color: $lightGray;
  font-weight: 400;
  text-transform: uppercase;
}

.subscriptionDate {
  font-size: 14px;
  margin-bottom: 0;
  color: $grey;
  font-weight: 600;
}

.restartSubscription {
  padding: 0 8px;
  font-size: 14px;
  background-color: rgba(var(--primary-color-rgb), 0.26) !important;
  color: $theme-color !important;
  border: 0;
  border-radius: 6px;
  box-shadow: none !important;
  @include ripple;
  height: 28px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 3px;
}

.cancelBilling {
  @extend .restartSubscription;
  margin-bottom: 0;
  margin-left: 10px;
}

.currentTier {
  border: 1px solid $theme-color;
  border-radius: 8px;
  padding: 3px 5px;
  cursor: default !important;
}

.currentPlan {
  font-size: 14px;
  color: $grey;
  margin-top: 10px;
  display: flex;

  img {
    height: 25px;
    margin: -2px 5px 0 5px;
  }
}

.editSubscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 2px solid rgba($black, 0.08);
  margin-top: 12px;

  > div {
    width: 49%;
  }

  .sectionTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 0;

    h4 {
      font-size: 14px;
      color: $mediumGray;
      text-transform: uppercase;
    }
  }

  .custom-control {
    margin-top: 10px;
  }

  .custom-control-label {
    font-weight: 400;
  }
}

// card feed
.cardFeed,
.paymentWrap {
  border: 0;
  margin: 15px auto;
  border-radius: 12px;
  overflow: hidden;
}

.cardFeed {
  background: transparent;
  max-width: 580px;

  .card-header {
    background: transparent;
    padding: 10px 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;

    h4 {
      margin-bottom: 2px;
      font-size: 14px;
      color: $grey;
      font-weight: 600;
    }

    .profileID {
      font-size: 12px;
      color: $grey !important;
      text-decoration: none !important;
      cursor: pointer;
      font-weight: 300;
    }

    p {
      font-size: 12px;
      color: $theme-color;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    position: relative;

    .customPaddingMob,
    p {
      margin-bottom: 8px;
      white-space: pre-wrap;
    }
  }

  .postTime-Feed {
    color: $lightGray;
    font-size: 12px;
    margin-top: 6px;
    margin-left: 1px;
  }

  .card-text {
    font-size: 14px;
    color: $grey;
    margin-bottom: 10px;
  }
}

.userImg {
  width: 36px;
  height: 36px;
  max-width: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid $theme-color;
  // background-image: linear-gradient(white, white),
  //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
}

video:focus {
  outline: 0 !important;
}

.liveEventActionBtns {
  position: absolute;
  left: 8px;
  bottom: 15px;

  .btn {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: transparent !important;
    color: $white !important;
    @include ripple;

    i {
      margin-right: 6px;
      font-size: 18px;
      vertical-align: -1px;
    }
  }

  .btn .fa-heart,
  .btn .fa-comment {
    color: $theme-color;
  }
}

.footerFeed {
  background-color: transparent;
  border-top: 0;
  padding: 10px 0 0;

  .btn {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: transparent !important;
    color: $grey !important;
    @include ripple;

    i {
      font-size: 20px;
    }

    &:first-child {
      padding-left: 1px;
    }
  }

  .btn .fa-heart,
  .btn .fa-comment {
    color: $theme-color;
  }

  .fa-comment,
  .fa-comment-o {
    vertical-align: 2px;
  }

  span {
    font-size: 11px;
    color: $lightGray;
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;

    &:first-child {
      margin-left: 1px;
    }
  }

  img {
    height: 20px;
    vertical-align: -3px;
  }
}

.mediaPhoto-Feed {
  border-radius: 16px;
  height: 100%;
  width: 100%;
  // border: 1px solid rgba($black, 0.01);
  object-fit: cover;
  max-height: 580px;
}

.mediaPhotoWrap-Feed,
.mediaVideoWrap-Feed,
.mediaAudioWrap-Feed {
  width: 100%;
  height: 100%;
  max-height: 580px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
}

.mediaVideoWrap-Feed {
  video,
  .customVideoWrapper {
    max-height: 580px;
    width: 100%;
  }

  video {
    display: block;
  }
}

.mediaPhotoWrap-Feed {
  video {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    max-height: 580px;
  }
}

.feedCarousel,
.profileCarousel {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba($black, 0.04);
  border-radius: 16px;

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 450px;
    width: 100%;
    border-radius: 16px;
  }

  .videoPreview {
    height: 100%;
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video,
  .carouselImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: #111;
  }

  .carouselImg {
    object-fit: cover;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 24px;
    width: 24px;
    background-color: rgba($white, 0.4);
    box-shadow: 0px 0px 5px 0 rgba($black, 0.3);
    border-radius: 50%;
    line-height: 25px;
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;
  }

  .carousel-control-next-icon:before,
  .carousel-control-prev-icon:before {
    font-size: 14px;
    color: #ccc;
  }

  .audioWrapperFeedCarousel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid rgba($black, 0.025);
    border-radius: 16px;
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 10px;
    padding: 4px;
    max-width: 100%;
    bottom: 6px;
    display: none;

    li {
      width: 6px;
      min-width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #eee;
    }

    .active {
      background: linear-gradient(
        rgba(var(--primary-color-rgb), 1),
        rgba(var(--primary-color-rgb), 0.5)
      );
    }
  }

  .mediaCountCarousel {
    z-index: 99;
    right: 10px;
    left: unset;
    top: 10px;
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

.profileCarousel {
  border: 0;
  width: 100%;
  border-radius: 0;

  .carousel,
  .carousel-inner,
  .carousel-item {
    border-radius: 0;
    height: unset;
  }

  .carousel {
    overflow: hidden;
    position: relative;
  }

  .carousel-inner {
    width: 100%;
    position: relative;
  }

  .carousel .carousel-item {
    height: 100%;
    overflow: hidden;

    .imgCropWrap {
      position: absolute;
      width: 100%;

      padding-top: 33.3333333%;
      height: 0;
    }

    img,
    video {
      border-radius: 0;
      height: 100%;
      width: 100%;
    }

    video {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      &.widthAuto {
        // if width > height
        width: auto;
        height: 100%;
      }

      &.heightAuto {
        // if height > width or height = width
        width: 100%;
        height: auto;
      }
    }
  }
}

audio:focus {
  outline: 0;
}

.readMore {
  color: $grey;
  font-size: 12px;
  margin-bottom: 7px;

  .btn {
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: $mediumGray !important;
    margin-left: 2px;
    vertical-align: 0;
    text-decoration: none !important;
    height: 17px;
  }
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
  color: $grey;
  font-weight: 400;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.45;
}

.pollOptionWrap {
  display: block;

  .radio {
    margin-bottom: 2px;
    padding-left: 0;
  }

  input {
    visibility: hidden;
  }

  label {
    width: 100%;
    position: relative;

    .pollOptions {
      position: absolute;
      top: 12px;
      left: 12px;
      color: $mediumGray;
      color: #edb300;
      font-size: 14px;
      font-weight: 400;
      width: 80%;
    }
  }

  .form-check-input {
    margin-top: 3px;
  }

  .progress {
    height: 44px;
    border-radius: 60px;
    border: 2px solid #e0e0e0;
    background: $white;
  }

  .progress-bar {
    background: #e0e0e0;
    border-radius: 60px;
  }

  .pollPercent {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 14px;
    color: $mediumGray;
    color: #edb300;
    font-weight: 500;
  }

  input:checked {
    & ~ .progress {
      border: 2px solid $theme-color;
      // background-color: $theme-color;

      .progress-bar {
        background: $theme-color;
      }
    }

    & ~ .pollOptions,
    & ~ .pollPercent {
      color: $grey;
      color: #edb300;
    }

    & ~ .pollOptions {
      font-weight: 500;
    }
  }
}

.totalVotes {
  font-size: 14px;
  color: $lightGray;
}

// live events
.upcomingPastEventWrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    color: $grey;
    font-size: 16px;
    font-weight: 500;
  }

  .react-switch {
    margin-right: 5px;
    margin-top: -1px;

    .react-switch-bg {
      border: 0;
    }
  }
}

.react-switch .react-switch-bg {
  border: #e5e5e5 solid 1px;
}

.liveStreamEventName,
.ppvVideoName {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  color: $grey;
}

.ppvVideoName {
  cursor: pointer;
}

.eventAttendingCount {
  font-size: 14px;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px;
  background-color: rgba($white, 0.15);
  border-radius: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 34px;
  height: 34px;
}

.shadowOverlayImg,
.vaultFolderOverlay {
  width: 100%;
  height: 100%;
  background: rgba($black, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
}

.vaultFolderOverlay {
  background-color: transparent;
  z-index: 99;
}

.liveStreamEventDate {
  font-size: 14px;
  position: absolute;
  background-color: $theme-color;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: $white;
  z-index: 99;
  top: 0;
  left: 12px;
  padding: 10px 8px 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  .eventTime {
    margin-top: 2px;
    text-transform: uppercase;
  }
}

.eventPrice {
  display: flex;

  div + div {
    margin-left: 18px;
  }
}

.eventPriceWrap {
  color: $grey;
  font-size: 13.5px;
  font-weight: 500;
  margin-bottom: 10px;
}

.rsvpBtn {
  background-color: $theme-color !important;
  border-radius: 12px;
  padding: 0 6px;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  color: $white !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.5px;
  position: absolute !important;
  bottom: 8px;
  right: 8px;
  @include ripple;
  width: 120px;
  height: 40px;
  line-height: 1;
}

.videoPreviewTag,
.videoPreviewTag-Feed {
  background-color: $theme-color;
  color: $white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 0 6px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  line-height: 23px;
  height: 23px;
  z-index: 99;
}

.videoPreviewTag-Feed {
  bottom: unset;
  top: 15px;
}

.videoPreviewLiveEvent {
  top: 5px;
  left: 5px;
  right: unset;
  bottom: unset;
}

.rsvpStatusWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    color: $lightGray;
    margin-bottom: 0;
    font-size: 12px;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    margin: 0 2px 0 1px;
  }
}

.liveEventDetailsWrap,
.ppvPreviewStatsWrap {
  padding: 15px;
  border-radius: 12px;
  background: $white;

  audio {
    width: 96%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.ppvPreviewStatsWrap {
  audio {
    position: unset;
    left: unset;
    top: unset;
    transform: unset;
  }
}

.rsvpResponse,
.attendingStatus {
  margin-top: 15px;

  h4 {
    margin-bottom: 12px;
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.rsvpResponseBtn {
  padding: 0 10px;
  height: 44px;
  line-height: 42px;
  width: 95px;
  color: $lightGray !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 25px;
  background-color: #f5f5f5 !important;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  @include ripple;
  margin-top: 5px;
}

.rsvpResponseBtn-active {
  background-color: $theme-color !important;
  color: $white !important;
  @include ripple;
}

.rsvpResponseBtn + .rsvpResponseBtn {
  margin-left: 12px;
}

.paymentLiveStream {
  margin-top: 25px;
  margin-bottom: 10px;

  h4 {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
  }
}

.rsvpPaymentOptions {
  margin-top: 15px;
  margin-bottom: 20px;

  label {
    font-size: 14px !important;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.cancelledEventWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: rgba($black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  div {
    font-size: 16px;
    background-color: #dc3545;
    color: $white;
    height: 48px;
    line-height: 48px;
    border-radius: 12px;
    padding: 0 22px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

// ppv
.toggleBtn {
  font-size: 14px;
  color: $grey !important;
  padding: 0 0.4rem;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  display: none;
}

.customFilterWrap {
  background-color: $white;
  border-radius: 15px;
  padding: 10px 8px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  align-items: flex-end;

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .form-group,
  .addBtn {
    margin: 5px;
  }

  .form-control {
    padding: 6px;
  }

  .input-group {
    width: 200px;
    margin: 5px;

    .form-control {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border: 1px solid $inputBorder;

      &:focus {
        border: 1px solid $theme-color;
      }
    }
  }
}

.videoPlayBtn {
  border: 0;
  color: $white !important;
  font-size: 50px;
  background: transparent !important;
  box-shadow: none !important;
  @include ripple;
  padding: 0 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 50px;
  text-shadow: 0px 0px 7px rgba($black, 0.3);
}

.lockIconPPV {
  height: 80px !important;
  width: 58px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  fill: $theme-color;
}

.purchaseNViewsWrap {
  margin-top: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: $lightGray;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 1px;
  }
}

// FAQ
.faqCategoryTitle {
  font-size: 18px;
  color: $grey;
  font-weight: 600;
  margin-top: 25px;
}

.someTipsFaq {
  font-size: 14px;
  color: $lightGray;
  line-height: 1.4;
  font-weight: 400;

  a {
    color: $theme-color;
  }
}

.searchBarFaqWrap,
.searchChatListWrap {
  margin-top: 18px;
  margin-bottom: 18px;
  position: relative;

  input {
    height: 44px;
    box-shadow: none !important;
    border: 0;
    letter-spacing: 0.3px;
    padding: 0 34px;
    background: #f2f2f2 !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: $lightGray;
    line-height: 44px;
  }

  i {
    color: $lightGray;
    font-size: 14px;
  }

  .searchIconFaq,
  .clearSearch,
  .searchIcon {
    position: absolute;
    top: 14.5px;
    left: 12px;
  }

  .clearSearch {
    right: 12px;
    left: unset;
    top: 13px;
    font-size: 16px;
    cursor: pointer;
  }
}

.searchChatListWrap {
  margin-top: 10px;
}

.faqCategoryWrap {
  height: 160px;
  border-radius: 16px;
  background: #fafafa;
  width: 100%;
  border: 1px solid rgba($black, 0.02);
  box-shadow: 0 6px 10px 0 rgba($black, 0.06);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;

  i {
    font-size: 50px;
    color: $theme-color;
  }

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 500;
    margin-bottom: 0;
  }
}

#accordion {
  margin-bottom: -1px;

  .card {
    border: 0;
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    border-radius: 0;
    margin-bottom: 0;
  }

  .card-header {
    background: $white;
    border: 0;
    padding: 16px 5px 14px;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    i {
      font-size: 14px;
      color: $mediumGray;
      margin-top: 1px;
      margin-left: 5px;
      margin-right: 0;
      transition: all 0.3s;
    }
  }

  .card-header[aria-expanded="true"] i {
    transform: rotate(-180deg);
    transition: all 0.3s;
    margin-top: 3px;
  }

  .faqQues,
  p {
    font-size: 14px;
    line-height: 1.4;
    color: $grey;
    margin-bottom: 0;
    font-weight: 500;
  }

  .card-body {
    padding: 15px 5px;
    border-top: 1px solid #4d4d4d;
    border-bottom: 0;
    border-radius: 0 !important;
  }

  p {
    color: $lightGray;
    font-weight: 400;
  }

  a {
    color: $theme-color;
    display: inline-block;
    text-decoration: underline !important;
  }
}

.youtubeEmbedWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  max-width: 100%;
  margin: 25px 0 10px;
}

.youtubeEmbedWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profileCard {
  margin-top: 0;
  border-radius: 0;
  border: 0;
  max-width: 801px;

  .card-header {
    position: relative;
    flex-direction: column;
    padding: 0 !important;
  }

  .card-body {
    padding: 0 10px !important;
    margin-top: 7px;
  }

  .profileDesc {
    font-size: 13px;
    margin-top: 10px;
    color: $mediumGray;
    margin-bottom: 0;

    .readMore {
      font-size: 13px;
    }
  }
}

.coverImgWrap {
  height: 280px;
  min-height: 280px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba($black, 0.03);

  .coverImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// influencer Details
.influencerDetailsWrap {
  display: flex;
  width: 100%;
  padding: 0 10px;
  z-index: 9;

  .img100by100 {
    margin-top: -40px;
    border: 2px solid $theme-color;
    // background-image: linear-gradient(white, white),
    //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
    // background-origin: border-box;
    // background-clip: content-box, border-box;
  }
}

.profileInfoWrap {
  display: flex;
  margin-left: 15px;
  width: 100%;
  height: fit-content;

  div {
    text-align: center;
    margin-top: 8px;
    width: 100%;

    &:not(:first-child) {
      border-left: 1px solid #e5e5e5;
    }
  }

  h5 {
    font-size: 18px;
    color: $grey;
    margin-bottom: 0;
    font-weight: 600;
  }

  p {
    color: $lightGray !important;
  }
}

.btnSubscribe {
  max-width: max-content;
  margin: 25px auto 10px;
  padding: 0 25px;
  font-size: 14px;
  display: block;
}

.offers-Tier {
  margin-top: 15px;
  margin-bottom: 10px;
}

.bundles-Tier,
.offers-Tier {
  .bundles-Tier-Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($black, 0.08);
    margin-top: 15px;
    padding-bottom: 8px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 0;
    color: $grey;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    text-transform: capitalize;
    color: $lightGray;
    padding: 0 !important;
  }

  .custom-control {
    input,
    label {
      cursor: pointer;
    }

    & + label {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.tabsWrap-InfluencerDetails {
  margin-top: 20px;
  padding: 0 10px;

  h4 {
    color: $grey;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
  }

  .customTabs {
    width: 100%;
    justify-content: center;

    .nav-link,
    .nav-link.active {
      text-align: center !important;

      .activeIcon {
        margin-top: 0;
      }
    }

    .nav-item {
      margin-bottom: -2px;
    }
  }

  .btn {
    padding: 3px 6px;
    background: transparent !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    @include ripple;
    margin-top: -2px;
  }
}

.subscribeInfBtn {
  background: $theme-color !important;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
  color: $white !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 25px;
  @include ripple;
  height: 32px;
  line-height: 32px;
}

.subscribeInfBtnPublic {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
}

// tab css
.customTabs {
  border-bottom: 1px solid #e6e6e6;

  .nav-item {
    flex: 0.5;
    margin-bottom: -2px;
  }

  .nav-link,
  .nav-link:hover {
    border: 0;
    border-bottom: 4px solid transparent;
    text-align: center;
    padding: 0.4rem 0.25rem;
    color: $lightGray !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  .nav-link {
    div {
      font-size: 20px;
    }

    .activeIcon {
      display: none;
    }

    .inactiveIcon {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .shoutIcon {
    color: #e0e0e0;
    font-size: 23px;
    margin-top: -4px;
  }

  .nav-link.active {
    border-bottom: 2px solid $theme-color;
    background: transparent;
    color: $grey !important;

    .activeIcon {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: -2px;
    }

    .inactiveIcon {
      display: none;
    }

    .shoutIcon {
      color: $grey;
    }
  }

  .activeIcon,
  .inactiveIcon {
    height: 24px;
  }
}

.customTabContent {
  border: 0;
  margin-top: 0;
  background: transparent;

  .tab-pane {
    padding: 2px;
  }
}

// notifications
.notificationSelect {
  margin-bottom: 0;

  select {
    background-position-y: 7px;
  }

  .form-control {
    border: 0;
    height: 40px;
    padding-right: 32px;
    padding-left: 4px;
    text-transform: uppercase;
    background-color: transparent !important;
  }
}

.customTabContent-Notifications {
  background-color: $white;
  margin-top: 10px;
  border-radius: 12px;

  .nav-link i {
    font-size: 20px;
    display: block;
    margin-bottom: 6px;
  }

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
  }

  .uploadBtn {
    margin-bottom: 0 !important;
    font-size: 12px !important;
  }

  .notificationOptions {
    label {
      margin-bottom: 0 !important;
      font-weight: 400 !important;
    }
  }
}

.notificationsWrap {
  padding: 7px 5px;
  border: 0;
  margin-bottom: 0;
  background: transparent;

  .userImg {
    height: 46px;
    border: 1px solid rgba($black, 0.05);
    background-image: none;
    width: 46px;
    max-width: 46px;
    min-width: 46px;
    margin-right: 6px;
  }

  &:last-child {
    border: 0;
  }
}

.notificationDay,
.paymentDay {
  font-size: 13px;
  letter-spacing: 0.5px;
  color: $lightGray;
  margin: 25px 0 5px;
  text-transform: uppercase;
  padding-left: 2px;
}

.paymentDay {
  margin-bottom: 0;

  &:first-child {
    margin-top: 8px;
  }
}

.notifications {
  display: flex;
  justify-content: flex-start;
  // align-items: center;

  .profileID {
    color: $grey;
    font-weight: 600;
    padding: 0 2px 0 0;
    font-size: 13.5px;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
      color: $theme-color;
    }
  }

  span {
    font-size: 13px;
    color: $mediumGray;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
    font-size: 13px;
    color: $mediumGray;
    display: inline-block;

    .btn {
      padding: 0 2px 1px;
      font-size: 13px;
      color: $theme-color;
      text-decoration: none !important;
    }
  }

  .commentTime {
    display: block;
    margin-left: 0;

    &:before {
      display: none;
    }
  }
}

// settings
.header_Settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 15px;

  h4 {
    color: $grey;
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.customSwitch {
  width: 36px !important;
  height: 20px !important;

  .switch-slider {
    border: 1px solid #a0a3bd;
    background: #a0a3bd;
  }

  .switch-slider::before {
    background-color: $white;
    border-color: $white;
    height: 14px;
    width: 14px;
  }

  .switch-input:checked + .switch-slider {
    background-color: $theme-color;
    border-color: $theme-color;
  }

  .switch-input:checked + .switch-slider::before {
    border-color: $white;
    transform: translateX(15px);
    background: $white;
  }
}

.img120by120 {
  background-color: $white;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $theme-color;
  // background-image: linear-gradient(white, white),
  //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
  position: relative;
  margin-bottom: 20px;

  .imagePreview {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 40px;
      color: #999;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.uploadBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 15px;
  position: absolute;
  background-color: $theme-color;
  border-radius: 50%;
  bottom: 1px;
  right: 5px;
  margin: 0;
  color: $white !important;
  line-height: 30px !important;
  text-align: center;
}

.photoUpload {
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  letter-spacing: 0.5px;
  background-color: $white;
  padding: 0;
  font-size: 15px;
  cursor: pointer;
  color: $mediumGray;
  border: 1px solid #e4e7ea;
  text-align: center;
}

.saveBtn {
  max-width: max-content;
  padding: 0 40px;
  height: 46px;
  margin: 25px auto 15px;
  display: block;
  line-height: 46px;
}

// manage card
.addBtn {
  border-radius: 30px;
  width: max-content;
  padding: 0 20px;
  font-size: 14px;
  margin: 0;
  height: 38px;
  line-height: 38px;
}

.paymentWrap {
  box-shadow: none;
  margin: 15px 0 18px;

  .card-body {
    padding: 0;
  }

  .cardBrand {
    color: #393939;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
  }

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
  }
}

.savedCardInfo {
  li {
    padding: 22px 0 19px;
    border: 0;
    border-radius: 0 !important;
    margin-bottom: 0;
    border-bottom: 2px solid #ccc;
    font-size: 14px;
    background-color: transparent;

    &:last-child {
      border-bottom: 0;
    }

    h5 {
      margin-bottom: 4px;
      font-size: 14px;
      color: $mediumGray;

      div {
        display: inline-block;
      }

      span {
        width: 5px;
        height: 5px;
        display: inline-block;
        background-color: $mediumGray;
        border-radius: 50%;
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }

    p {
      font-size: 14px;
      color: $mediumGray;
      margin-bottom: 0;
    }

    .greenBg,
    .yellowBg {
      background: rgba(91, 209, 159, 0.2);
      border-radius: 8px;
      height: 40px;
      width: 40px;
      margin-right: 8px;
      font-size: 22px;
      text-align: center;
      line-height: 40px;
      color: #5bd19f;
    }

    .yellowBg {
      color: #fdd015;
      background: rgba(253, 208, 21, 0.2);
    }

    .makeDefault {
      color: $mediumGray !important;
      font-size: 14px;
      padding: 0 5px;
      margin-top: -2px;
      text-align: right;
      text-decoration: none !important;
    }

    .btn-ghost-danger {
      padding: 0 5px;
      font-size: 14px;
      margin-top: -2px;
      margin-left: 7px;
      width: max-content;
      align-self: flex-end;
    }
  }
}

// search pg
// .searchChatListWrap {
//   margin-top: 0;
// }

.influencerCard-Search {
  padding: 7px 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;

  p {
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 0;
    color: $lightGray;
    font-weight: 400;
  }

  .profileID {
    font-size: 14px;
    font-weight: 600;
    color: $grey;
    text-decoration: none;
    text-align: left;
    padding: 0;
  }
}

.subscribeBtn {
  padding: 0 12px;
  border: 1px solid $theme-color !important;
  color: $white !important;
  font-size: 12px;
  border-radius: 25px;
  box-shadow: none !important;
  background: $theme-color !important;
  @include ripple;
  height: 34px;
  font-weight: 600;
  line-height: 32px;
  margin-left: 5px;
  flex-shrink: 0;
}

.paymentHistoryList {
  li {
    padding: 9px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    border: 0;
    background: transparent;

    img {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border: 1px solid rgba($black, 0.05);
      border-radius: 50%;
      min-width: 40px;
    }

    p {
      margin-top: -1px;
      margin-bottom: 3px;
    }

    p,
    .profileID {
      font-size: 14px;
      font-weight: 500;
      color: $grey;
    }

    .profileID {
      padding: 0;
      height: unset;
      text-decoration: none;
      margin-top: -3px;
    }

    .paymentDate {
      color: $lightGray;
      font-weight: 400;
      font-size: 12px;
    }

    .amtPaid {
      font-size: 14px;
      color: $theme-color;
      font-weight: 600;
      margin-left: 8px;
      margin-top: -1px;
    }

    a {
      color: $grey;
    }
  }
}

// chat list
.initiateMsg {
  padding: 0 6px;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
  height: 22px;
  line-height: 22px;
  font-size: 18px;
  color: #bbb !important;
  margin-right: 5px;

  img {
    height: 18px;
    margin-top: -2px;
  }
}

.recentWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey;
  margin-bottom: 5px;

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.chatList {
  margin-bottom: 15px;

  .list-group-item {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    border: 0;
    border-radius: 0 !important;
    padding: 6px 0;
    background-color: $white;
    text-decoration: none !important;
  }
}

.userChatList {
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  h5 {
    color: $grey !important;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .userImg {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  p,
  span {
    font-size: 12px;
    margin-bottom: 0;
    color: $lightGray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 20px;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  // span {
  //   width: 85px;
  // }
}

.customDropdown {
  list-style-type: none;

  .dropdown-menu {
    right: 3px !important;
    left: auto !important;
    top: 41px !important;
    transform: none !important;
    border: 0 !important;
    padding: 0;
    box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  }

  .dropdown-item {
    outline: 0 !important;
    border: 0;
    font-size: 13px;
    padding: 9px 10px 8.5px;
    color: $mediumGray !important;
    background: transparent !important;
  }

  .textWarning {
    color: $error !important;
  }

  .dropdown-item + .dropdown-item {
    border-top: 1px solid rgba($black, 0.06);
  }
}

.unreadMsg {
  p,
  h5 {
    color: $theme-color !important;
  }

  span {
    color: $theme-color !important;
  }
}

// chat
.conversationDetails {
  overflow: auto;
  height: calc(100vh - 255px);
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;
  padding-right: 8px;
}

.receiverWrap,
.senderWrap {
  margin-top: 5px;
  width: 90%;
  margin-bottom: 12px;
}

.msg {
  // padding: 12px 18px 12px 15px;
  // border-radius: 25px;
  padding: 10px 15px;
  margin-bottom: 3px;
  border-radius: 12px;

  pre {
    color: $mediumGray;
    font-size: 14px;
    word-break: break-all;
  }
}

.bgOffWhite {
  background: #f2f2f2;
}

.bgThemeColor {
  background-color: $theme-color !important;

  pre {
    color: $white;
  }
}

.or {
  pre {
    color: $white;
  }
}

.senderWrap {
  margin-left: 10%;

  .sentTime {
    text-align: right;
  }

  .msg {
    border-bottom-right-radius: 0;
  }
}

.sentTime {
  color: $lightGray;
  font-size: 12px;
}

.receiverWrap {
  .bgOffWhite {
    align-self: flex-start;
  }

  .sentTime {
    margin-left: 40px;
  }

  .msg {
    border-bottom-left-radius: 0;
  }
}

.chatDate {
  font-size: 14px;
  color: $lightGray;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.senderImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba($black, 0.05);
  margin-right: 5px;
}

.typeMsgWrap {
  margin-top: auto;
  padding: 8px 8px 8px 0;

  .emoji-picker-react {
    margin-bottom: 5px;
  }
}

.mediaPhotoWrap,
.mediaVideoWrap,
.mediaAudioWrap {
  width: 100%;
  max-width: 262px;
  height: 100%;
  max-height: 272px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($black, 0.05);
  margin-bottom: 3px;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mediaAudioWrap {
  width: 250px;
  height: 150px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mediaVideoWrap video,
.mediaAudioWrap audio {
  width: 100%;
  height: 100%;
}

.addMessageWrap {
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
}

.receiverInfo-Chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  min-height: 52px;
  border-bottom: 1px solid rgba($black, 0.05);
}

.receiverName-Chat {
  cursor: pointer;

  h4 {
    font-size: 14px;
    margin-bottom: 1px;
    font-weight: 500;
    color: $grey;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: $mediumGray;
  }
}

.profileMsgPg {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}

.sendMsg,
.sendMoney {
  background: transparent !important;
  padding: 0 4px;
  height: 36px;
  line-height: 36px;
  border: 0 !important;
  @include ripple;
  position: absolute;
  right: 8px;
  bottom: 12px;

  img {
    width: 20px;
  }
}

.sendMoney {
  right: unset;
  left: 8px;
  color: $theme-color !important;
  bottom: 12px;

  svg {
    width: 22px;
    height: 22px;
    fill: $theme-color;
  }
}

.tipAmtWrap {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;

  .btn {
    height: 50px;
    line-height: 46px;
    padding: 0 15px;
    border: 2px solid $theme-color !important;
    border-radius: 8px;
    color: $theme-color !important;
    font-size: 14px;
    font-weight: 600;
    background: transparent !important;
  }

  .btn.active {
    background: $theme-color !important;
    color: $white !important;
  }
}

.customTipAmt {
  margin: 10px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  line-height: 1;
  font-weight: 600;
  color: $lightGray;

  div {
    width: min-content;
    margin-top: 2px;
    min-width: 33px;
  }

  input {
    font-size: 50px;
    font-weight: 600;
    background: transparent !important;
    padding: 0 1px;
    border: 0;
    min-width: 34px;
    line-height: normal;
    height: unset;

    &:focus {
      border: 0 !important;
      background: transparent !important;
    }

    &::-webkit-input-placeholder {
      color: $lightGray;
      line-height: normal;
    }

    &::-moz-placeholder {
      color: $lightGray;
      line-height: normal;
    }

    &:-ms-input-placeholder {
      color: $lightGray;
      line-height: normal;
    }

    &:-moz-placeholder {
      color: $lightGray;
      line-height: normal;
    }
  }
}

.tipConfirmationTxt {
  font-weight: 500;
  font-size: 18px;
  color: $mediumGray;
  line-height: 1.5;
  margin-top: 25px;
  margin-bottom: 35px;
  text-align: center;
}

// msg preview
.msgPreview {
  border: 1px solid rgba($black, 0.08);
  border-radius: 8px;
  position: relative;
  margin: 7px 10px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  font-size: 14px;
  height: 57px;
  width: 76px;
  min-width: 76px;
  span {
    padding: 6px;
    text-align: center;
    font-size: 14px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.deletePreview {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  color: #ff2323 !important;
  outline: 0 !important;
  background-color: $white !important;
  border: 0;
  text-align: center;
  box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;
  outline: 0 !important;

  // i {
  //   line-height: 1.2;
  // }
}

.tipWrap,
.paidMessageText {
  background: $theme-color;
  color: $white;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 12px;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
}

.paidMessageText {
  width: fit-content;
  font-style: normal;
  margin-bottom: 3px;
  margin-top: 5px;
}

.emojiBtn {
  position: absolute;
  bottom: 2px;
  right: 5px;
  padding: 0.2rem 0.4rem;
  text-decoration: none !important;
  font-size: 15px;
  color: $theme-color !important;
  display: none;
  right: 46px;
}

aside.emoji-picker-react {
  width: 100% !important;
}

// comment section
.commentSection {
  background: $white;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 0 10px;
  margin-top: -5px;
  max-height: 380px;
  overflow: auto;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;

  span {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 5px;
    display: inline-block;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.cardFeed + .commentSection {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 7px;
}

.comments-Post {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 8px 13px 8px 14px;
  font-size: 13px;
  color: $mediumGray;
  position: relative;
  width: fit-content;
  border-top-left-radius: 0;

  p {
    font-size: 14px;
    color: $grey;
    margin-bottom: 0;
  }

  textarea {
    background-color: #fcfcfc !important;
    border-radius: 24px !important;
    color: $grey !important;
    padding: 12px 36px 12px 15px !important;
    width: 100%;
    border-radius: 24px !important;
    min-height: 47px;
  }

  &:has(textarea) {
    width: 95%;
  }
}

.commentOptions {
  .nav-link {
    padding: 0;
    margin-left: 4px;
  }

  .nav-link i {
    padding: 0;
    padding-left: 1px;
    width: 22px;
    height: 22px;
    max-width: 22px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba($black, 0.3);
    border-radius: 50%;
    color: $lightGray;
    font-size: 14px;
  }

  .dropdown-item i {
    margin-left: 0;
    margin-right: 6px;
    color: $lightGray !important;
  }

  .dropdown-menu {
    top: 24px !important;
  }
}

.replyBtn,
.commentLikeBtn {
  font-size: 13px;
  color: $lightGray !important;
  padding: 0 4px;
  height: 26px;
  line-height: 26px;
  margin: 0;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
}

.commentLikeBtn {
  margin-left: 8px;

  .fa {
    margin-right: 5px;
    vertical-align: -1px;
  }

  .fa-heart {
    color: $theme-color;
  }

  span {
    vertical-align: -1px;
  }
}

.commentTime,
.userName-Comment {
  font-size: 12px;
  color: $mediumGray;
  margin-bottom: 0;
}

.commentTime {
  color: $lightGray;
  margin-left: 15px;
  position: relative;

  &:before {
    content: "\f111";
    color: #bbb;
    font-family: "fontAwesome";
    font-size: 4px;
    position: absolute;
    top: 6px;
    left: -9px;
  }
}

.userImg-Comment {
  min-width: 34px;
  min-height: 34px;
  width: 34px;
  height: 34px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}

// post locked
.postLocked,
.postUnlocked {
  position: relative;
}

.postLocked {
  height: 400px;
}

.postLockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  // border: 1px solid rgba($black, 0.03);
  padding: 10px;
  z-index: 99;

  h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .iconMedia {
    font-size: 14px;
  }

  .imgMedia {
    height: 16px;
  }

  .iconLock {
    height: 70px;
    margin: auto;
    width: unset;
    fill: $theme-color;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

// spinner
.spinnerWrap,
.customLoader {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.customLoader {
  margin: 10px auto;
}

// feed

.feedContentCount {
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    border-radius: 25px;
    height: 27px;
    padding: 0 6px;
    background-color: $theme-color;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    width: 50px;
    text-align: center;
    margin-right: 4px;

    .fa {
      font-size: 15px;
      margin-right: 3px;
      vertical-align: -1px;
    }
  }
}

.paymentBtnTier {
  width: 91%;
  max-width: 310px;
  margin: 10px auto 0;
  font-size: 16px;
  color: $white !important;
  border: 0 !important;
  outline: 0 !important;
  border-radius: 30px;
  padding: 0px 6px;
  height: 39px;
  line-height: 40px;
  font-weight: 600;
  background-color: $theme-color !important;
  @include ripple;
  display: block;
}

.sharedContent {
  border: 1px solid rgba($black, 0.08);
  border-radius: 16px;
  // margin-top: 5px;
  overflow: hidden;
  padding-top: 12px;

  .mediaPhotoWrap-Feed,
  .mediaVideoWrap-Feed,
  .mediaPhoto-Feed {
    cursor: pointer;
  }

  .shadowOverlayImg,
  .mediaPhotoWrap-Feed,
  .mediaVideoWrap-Feed,
  .mediaPhoto-Feed {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mediaPhoto-Feed {
    border: 0;
    border-top: 1px solid rgba($black, 0.01);
  }

  .customPaddingMob {
    padding: 0 12px;
  }

  .ppvVideoName,
  .liveStreamEventName {
    margin-bottom: 7px;
  }
}

.sharedContentVault {
  padding-top: 0;

  .mediaPhotoWrap-Feed {
    margin-top: 0;
    cursor: default;
  }

  .mediaPhoto-Feed {
    border: 0;
    cursor: default;
  }
}

// non subscriber view
.alreadyHaveAccountTxt {
  font-size: 18px;
  font-weight: 500;
  color: $grey;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    color: $theme-color;
    font-size: 18px;
    font-weight: 500;
    padding: 0 4px;
    text-decoration: none;
  }
}

.headerNonSubscriber {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 2px 15px;
  box-sizing: border-box;
  background: $white;
  border-bottom: 1px solid rgba($black, 0.08);

  img {
    height: 32px;
  }
}

// vault
.vaultFolderWrap {
  border: none;
  border-radius: 16px;
  margin-bottom: 15px;

  .card-body,
  .folderCoverPic {
    border-radius: 16px;
    height: 210px;
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .folderCoverPic {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  video {
    height: 100%;
    width: 100%;
    max-height: 210px;
    border-radius: 16px;
  }

  .folderPrice {
    background: $theme-color;
    color: $white;
    border-radius: 25px;
    cursor: text;
    padding: 0 11px;
    font-size: 12px;
    font-weight: 600;
    line-height: 23px;
    height: 23px;
  }

  .vaultFolderInfo {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    position: absolute;
    padding: 8px 8px 0;
    width: 100%;
    left: 0;
    top: 0;
    // z-index: 110;
  }

  .unlockVaultFolder,
  .vaultUnlockTxt {
    color: $white !important;
    border: 0;
    box-shadow: none !important;
    background: rgba($white, 0.2) !important;
    min-width: 58px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 28px;
    height: 28px;
    padding: 0 12px;
    border-radius: 25px;
    font-weight: 600;

    i {
      font-size: 14px;
      margin-right: 5px;
      vertical-align: -1px;
    }
  }

  .vaultInfo {
    position: absolute;
    padding: 0 8px 8px;
    left: 0;
    bottom: 0;
    width: 100%;

    h4 {
      font-size: 14px;
      margin-bottom: 3px;
      color: $white;
      font-weight: 600;
    }

    p,
    .folderItemsCount {
      font-size: 12px;
      color: $white;
      display: inline-block;
      margin-bottom: 0;
    }

    .folderItemsCount {
      margin-top: 5px;
      font-weight: 600;
    }

    h4,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .vaultFolderPreview {
    width: 100%;
    height: 100%;

    div:first-child {
      width: 100%;
      height: 100%;
    }
  }
}

.customBreadcrumb {
  .breadcrumb {
    background: transparent;
    border: none;
    padding: 0 0.2rem;
    margin-bottom: 0;
  }

  .breadcrumb li {
    font-size: 18px;
    color: $theme-color;
    display: flex;
    align-items: center;
  }

  .breadcrumb li a {
    color: $grey;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
  }
}

.filesTxt {
  font-size: 16px;
  color: $grey;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  & + div .addBtn {
    padding: 0 15px;
  }
}

.vaultMediaThumbWrap {
  height: 200px;
  width: 100%;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  cursor: pointer;

  video,
  .vaultMediaThumb {
    width: 100%;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
  }

  .vaultMediaInfo {
    position: absolute;
    padding: 0 10px 8px;
    width: 100%;
    left: 0;
    bottom: 0;
    color: $white;

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.creatorInfoVault {
  background: $white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  label {
    color: $grey !important;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 14px !important;
  }

  h5 {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 5px;
  }

  a {
    font-weight: 400;
    color: $lightGray;
    margin-left: auto;
    font-size: 12px;
    text-decoration: none;
  }

  p {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 400;
    color: $mediumGray;
  }
}

.videoInModal,
.pdfInModal {
  width: 91%;
  height: 91%;
  position: relative;
  top: 4.5%;
  left: 4.5%;
  outline: 0 !important;
}

.pdfInModal {
  height: calc(100vh - 11.5%);
}

.audioInModal {
  outline: 0 !important;
  max-width: 600px;
  width: 91%;
  margin: 0 auto;
  display: block;
}

.customBorderVault {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 25px 0 40px;
  border-radius: 15px;
}

// image modal
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba($black, 0.98) !important;
  z-index: 1040 !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 94%;
  padding: 0 !important;
  border: 0 !important;
  height: 98%;
  top: 50% !important;
  border-radius: 12px !important;
  background: #f7f7f7;
  background: transparent !important;

  .lazy-load-image-background {
    display: block !important;
  }

  .mediaPhoto-Feed {
    object-fit: contain;
    max-height: unset;
  }

  &:has(.storyContainer) {
    border-radius: 0 !important;
  }
}

.closeModalFeed {
  font-size: 16px !important;
  border-radius: 50%;
  padding: 0;
  height: 21px;
  width: 21px;
  line-height: 21px;
  position: absolute;
  top: 12px;
  right: 3px;
  z-index: 999;
  color: #ff2323 !important;
  outline: 0 !important;
  background-color: $white !important;
  border: 0;
  box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;
}

//  live event streaming
.liveEventStreamingWrap {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: $white;
  overflow: hidden;
}

.liveEventStreaming {
  width: calc(100% - 360px);
  height: 100%;
  background: rgba($black, 0.95);
  padding: 0 !important;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.liveStreamCommentToggle {
  position: absolute;
  top: 66px;
  right: 15px;
  padding: 0;
  border: 0 !important;
  z-index: 99;
  background-color: rgba($black, 0.3) !important;
  border-radius: 8px;
  width: 40px;
  height: 34px;
  font-size: 20px;
  color: $white !important;
  transition: all 0.8s;

  i {
    vertical-align: 1px;
  }
}

.liveStreamCommentWrap {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  z-index: 99;
  transition: all 0.5s;
  transform: translateY(-150%);
  background-color: rgba($black, 0.3);

  &.show + .liveStreamCommentToggle {
    right: 310px;
    transition: all 0.3s;
  }

  &.collapsing {
    transform: translateY(-150%);
    transition: all 0.5s;
  }

  &.show {
    transform: translateY(0px);
    transition: all 0.8s;
  }
}

.commentSectionWrap-liveStream {
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  padding: 0 8px;
  position: relative;
  // background: $white;
}

.liveStreamBrief {
  padding: 8px 0 5px;
  border-bottom: 1px solid rgba($white, 0.15);
  position: relative;
  margin-bottom: 2px;
}

.creatorName-liveStream {
  margin-bottom: 0;
  font-size: 14px;
  // color: $lightGray;
  color: $white;
  margin-left: 5px;

  span {
    color: $theme-color;
  }
}

.liveStreamName {
  margin-top: 6px;
  font-size: 14px;
  // color: $lightGray;
  color: $white;
  font-weight: 400;
}

.comment-LiveStreamEvent {
  margin: 0;
  max-height: unset;
  padding: 0;
  padding-right: 8px;
  position: relative;
  height: calc(100% - 225px);
  padding-bottom: 10px;
  background-color: transparent;

  .noComments {
    font-size: 14px;
    // color: $lightGray;
    color: $white;
    margin-top: 12px;
    font-weight: 300;
  }
}

.commentArea-liveStream {
  // position: absolute;
  width: 100%;
  // left: 0;
  // bottom: 5px;
  padding: 8px 0;
  // background: $white;
  background-color: transparent;
}

.liveStreamingBtnOptions {
  display: flex;
  padding-left: 40px;
  margin-top: 4px;

  .btn {
    padding: 0.2rem 0.4rem;
    text-decoration: none !important;

    img {
      width: 20px;
    }
  }

  .btn + .btn {
    margin-left: 7px;
  }
}

.kdokPV9jgsyFtd2e8Ek2 .PutMpvQbQ8ffCLR93qIq {
  padding: 2px 11px;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  background: rgba($black, 0.3) !important;
  border-radius: 12px !important;
  top: 70px;
  left: 10px;
  right: unset !important;
  bottom: unset !important;
  height: unset !important;
  width: unset !important;
}

.emojiImgAsComment-liveStreaming {
  width: 24px;
}

// date range
.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border-radius: 8px !important;
  border: 0 !important;
  background-color: $inputBorder !important;
  width: 100%;

  .DateInput {
    width: calc(50% - 12px) !important;
    border-radius: 8px;
  }
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.DateInput_input {
  display: block;
  width: 100%;
  height: 46px;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box;
  color: $lightGray !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  border: 1px solid $inputBorder !important;
  padding: 6px 8px !important;
  border-radius: 8px !important;
  background-color: $inputBorder !important;
  outline: 0 !important;

  &:focus {
    border-color: $theme-color !important;
  }
}

.DateRangePickerInput_arrow_svg {
  width: 20px !important;
  height: 20px !important;
  margin-top: -4px;
  margin-right: 4px;
}

.CalendarMonth_caption {
  font-size: 16px !important;
  color: $grey !important;
}

.DayPicker_weekHeader_li {
  color: $lightGray;
}

.CalendarDay__default {
  border: 1px solid rgba($black, 0.1) !important;
  color: $grey !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $theme-color !important;
  color: $white !important;
  border-color: $theme-color !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:hover {
  background: rgba(var(--primary-color-rgb), 0.5) !important;
  border-color: rgba(var(--primary-color-rgb), 0.5) !important;
}

.CalendarMonth_table {
  margin-top: 3px;
  box-shadow: none;
}

.DayPicker__withBorder {
  border-radius: 4px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

// toast
.toast {
  background-color: $white !important;
  border-color: $theme-color !important;
  color: $theme-color !important;
  font-family: "Poppins", sans-serif !important;
  padding: 5px 12px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  letter-spacing: 0.3px;
  flex-basis: 0 !important;
}

.stripeWrapper {
  margin-top: 10px;
  margin-bottom: 5px;

  .StripeElement {
    border: 1px solid $inputBorder;
    background-color: $inputBorder;
    border-radius: 8px;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 2px;
  }

  .CardField-number {
    width: 140px !important;
  }
}

.stripeWrapper * {
  width: 100% !important;
}

.joinLiveEventWrap {
  display: block;
  margin: 40px auto 15px;
  max-width: 340px;
}

.joinLiveEvent {
  padding: 0 65px;
  display: block;
  width: max-content;
  height: 46px;
  line-height: 47px;
  margin: 0 auto;
}

.deleteAccountBtn {
  padding: 6px 10px;
  display: block;
  color: $error !important;
  font-size: 14px;
  margin: 20px auto 5px;
  text-decoration: none !important;
}

.vaultHighLighted {
  border: 1px solid $theme-color;
}

.onlineStatus {
  color: #4dbd74 !important;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 99;
  font-size: 12px;
}

// shout out
.shoutOutContentWrap {
  padding: 15px 10px;
}

.shoutOutIntroWrap {
  margin: 25px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .shoutOutTypeWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #e5e5e5;
    margin-bottom: 18px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: $grey;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 13px;
    color: $lightGray;
    margin-bottom: 25px;
    word-break: break-all;
  }
}

.publicShoutoutContent {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.publicShoutoutContent audio:focus,
.publicShoutoutContent audio:active,
.publicShoutoutContent video:focus,
.publicShoutoutContent video:active {
  box-shadow: none !important;
  outline: none !important;
  outline-style: none !important;
}

.uploadMedia-ShoutOut {
  width: 210px;
  max-width: 210px;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 5px;
  border: 1px dashed rgba($black, 0.15);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}

.shoutOutMediaWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    outline: 0 !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .audioWrapper {
    height: 266px;
  }

  .uploadMedia-ShoutOut {
    width: 100%;
    max-width: unset;
  }
}

.bioShoutOut {
  margin-top: 10px;
  margin-bottom: 18px;

  & ~ .form-group label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  p {
    font-size: 14px;
    color: $grey;
    margin-bottom: 0;

    .btn {
      font-size: 14px;
    }
  }
}

.pricingShoutOutWrap {
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.05) !important;
  text-align: center;
  margin-bottom: 5px;
  position: relative;
  width: 90px;
  max-width: 90px;
  border-radius: 6px;
  margin-top: 5px;
  border: 1px solid rgba($black, 0.06);

  i {
    position: absolute;
    top: 5px;
    right: 6px;
    font-size: 16px;
    color: $white;
  }
}

.durationShoutOut {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  color: $lightGray;
}

.pricingShoutOut {
  font-size: 20px;
  font-weight: 600;
  background-color: $theme-color;
  padding: 15px 2px 5px;
  color: $white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

// shout out
.shoutOutAccordion {
  margin-bottom: 5px;
  border: 0;

  .card-header {
    padding: 10px 0;
    background-color: $white;
    cursor: pointer;
    border-bottom: 0;
  }

  .creatorName {
    color: $grey;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1px;
    margin-top: -1px;
  }

  .headerIcon {
    font-size: 16px;
    margin-left: 8px;
    color: $grey;
    margin-top: 1px;
  }

  .shoutOutType,
  .shoutOutDate,
  .shoutOutStatus {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 0;
  }

  .shoutOutStatus {
    margin-right: 5px;
    text-transform: uppercase;
  }

  .message {
    color: $lightGray;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 400;
  }

  .card-body {
    padding: 5px 0;
    border-bottom: 1px solid rgba($black, 0.05);
    margin-bottom: 5px;

    p {
      color: $grey;
      font-size: 14px;
      margin-bottom: 0;
      margin-right: 6px;
    }

    h5 {
      color: $grey;
      font-size: 14px;
      margin-bottom: 0;
    }

    h4 {
      color: $grey;
      font-size: 16px;
      font-weight: 500;
      margin-top: 8px;
      margin-bottom: 10px;
    }

    .commentSection {
      padding: 5px 5px 0 5px;
      border: 1px solid rgba($black, 0.08);
      border-radius: 10px !important;
      margin-top: 25px;

      p {
        color: $lightGray;
        margin-bottom: 10px;
        margin-top: 5px;
        margin-left: 6px;
      }

      .userName-Comment {
        margin: 0;
      }
    }
  }

  .unreadOrder {
    background: rgba(var(--primary-color-rgb), 0.12);
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }

  label {
    color: $lightGray;
    font-size: 12px;
  }

  &:last-child {
    .card-body {
      border-bottom: 0;
    }
  }
}

.shoutOutMediaWrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;

  p {
    margin-bottom: 5px !important;
    margin-right: 0 !important;
    color: $lightGray !important;
  }
}

.preferredTime-LiveCall,
.liveVideo-ScheduleTime,
.shoutOutOccasionWrap {
  p {
    font-size: 12px !important;
    color: $lightGray !important;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 600;
    margin-top: 5px;
  }
}

.myOrders {
  width: unset;
  height: 38px;
  line-height: 38px;
  margin: 0;
  font-size: 14px;
  padding: 0 18px;
}

.commentShoutout {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;

  .sendMsg {
    bottom: 6px;
  }
}

.videoCallRoomModal {
  height: 100vh !important;
}

// video call room
.videoCallRoom {
  width: 100%;
  position: relative;
  height: 100vh !important;

  img {
    max-width: 55px;
  }
}

.videoRoomActionBtn {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.btnVideoRoomWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.btnVideoRoom {
  box-shadow: none !important;
  outline: 0 !important;
  border: 0 !important;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $theme-color !important;
  font-size: 24px;
  color: $white !important;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;

  img {
    height: 24px;
  }
}

.btnEndCall {
  background-color: #ea1c43 !important;

  img {
    height: 30px;
  }
}

.cameraOffTxt {
  position: absolute;
  z-index: 9;
  color: $white;
  left: 0;
  width: 100%;
  top: 35%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.joinBtn {
  width: 180px;
  background: $theme-color !important;
  border: 0 !important;
  color: $white !important;
  text-align: center;
  margin: 15px auto 5px;
  height: 42px;
  line-height: 1.3;
  padding: 0 6px;
  font-size: 16px;
  border-radius: 25px;
  display: block;
  outline: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  @include ripple;
}

.play-bar .rounded-box {
  .logo {
    display: none;
  }
}

.play-icon {
  display: none;
}

.copyClipboardBtn {
  background: $theme-color !important;
  font-size: 14px;
  color: $white !important;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 0 15px;
  margin-left: 5px;
  border-radius: 25px;
  font-weight: 600;
  height: 34px;
  line-height: 32px;
}

.shareShoutOut {
  margin-top: 20px;
  margin-bottom: 30px;
}

.socialMediaButton {
  margin-right: 10px;
  svg {
    border-radius: 6px;
  }
}

.pagination {
  li {
    a {
      padding: 0;
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      background-color: transparent !important;
      color: $theme-color;
    }
    &.disabled {
      pointer-events: none;
    }
    &.active {
      a {
        background-color: $white !important;
        color: $theme-color !important;
        border-color: $theme-color !important;
      }
    }
  }
}

.nonSubscriberView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  h4 {
    margin-bottom: 0;
  }
}

.profileSummaryWrap {
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;

  h5 {
    font-size: 18px;
    color: $grey;
    margin-bottom: 5px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 0;
  }

  .profileSummary {
    flex: 0.5;

    &:not(:first-child) {
      padding-left: 15px;
    }
  }
}

.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 7%;
  top: 45%;
  height: 10%;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
  cursor: pointer;
  height: 34px;

  &:before {
    content: "\f054";
    font-family: "FontAwesome";
    color: $theme-color;
    font-size: 24px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.carousel-control-prev-icon {
  background-image: none;

  &:before {
    content: "\f053";
  }
}

.mediaPhotoWrap,
.mediaPhotoWrap-Feed {
  .groupMediaWrap:nth-child(1) {
    .shadowOverlayImg {
      border-radius: 0;
      border-top-left-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(2) {
    border-left: 1px solid rgba($black, 0.05);
    .shadowOverlayImg {
      border-radius: 0;
      border-top-right-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(3) {
    border-top: 1px solid rgba($black, 0.05);
    .shadowOverlayImg {
      border-radius: 0;
      border-bottom-left-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(4) {
    border-left: 1px solid rgba($black, 0.05);
    border-top: 1px solid rgba($black, 0.05);
    .shadowOverlayImg {
      border-radius: 0;
      border-bottom-right-radius: 12px;
    }
  }

  .groupMediaWrap {
    width: 130px;
    height: 135px;
    cursor: pointer;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .videoPlayBtn {
      z-index: 99;
      font-size: 45px;
      line-height: 45px;
    }

    audio {
      width: 90%;
    }
  }

  .groupMediaWrapVideo {
    background-color: #222;
  }

  .lazy-load-image-background {
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.mediaPhotoWrap,
.mediaAudioWrap {
  .iconLock {
    height: 36px;
    fill: $theme-color;
  }

  .postLockedOverlay {
    border: 0;

    .mediaIcon {
      position: absolute;
      top: 12px;
      left: 12px;
      font-size: 20px;
      color: $theme-color;
    }

    .feedContentCount {
      top: 10px;
    }
  }

  .paymentBtnTier {
    font-size: 14px;
    margin-top: 0;
  }
}

.mediaPhotoWrap-Feed {
  .groupMediaWrap {
    width: 50%;
    height: 300px;
  }
}

.seeMoreMedia,
.seeMoreMedia-Feed {
  background-color: rgba($black, 0.65);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 135px;
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 4px;
  border-left: 1px solid $white;
  border-top: 1px solid $white;

  .btn {
    color: $white !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }
}

.seeMoreMedia-Feed {
  height: 50%;
  width: 50%;
  padding: 8px 12px;
}

.multipleMediaModal {
  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }

  .lazy-load-image-background {
    display: block !important;
    width: 94%;
    margin: 0 auto;
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 6px;
    padding: 4px;
    max-width: 100%;
    bottom: 5px;

    li {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #dcdcdc;
    }

    .active {
      background: linear-gradient(
        rgba(var(--primary-color-rgb), 1),
        rgba(var(--primary-color-rgb), 0.3)
      );
    }
  }

  .carousel-control-prev {
    left: -2%;
  }

  .carousel-control-next {
    right: -2%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 24px;
    width: 24px;
    background-color: rgba($white, 0.4);
    box-shadow: 0px 0px 5px 0 rgba($black, 0.3);
    border-radius: 50%;
    line-height: 25px;
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;
  }

  .carousel-control-next-icon:before,
  .carousel-control-prev-icon:before {
    font-size: 14px;
    color: #ccc;
  }

  .videoPreview {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}

.addPWATooltipWrap {
  width: 91%;
  border-radius: 6px;
  box-shadow: 0 0 0.5rem rgba($black, 0.1);
  position: fixed;
  bottom: 28px;
  left: 4.5%;
}

.addPWATooltip {
  padding: 12px 10px;
  border-radius: 5px;
  background-color: $white;
  position: relative;
  font-size: 14px;
  color: $mediumGray;
  letter-spacing: 0.3px;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    font-size: 32px;
    color: #bbb;
    margin-right: 8px;
  }

  .btn {
    position: absolute;
    top: -6px;
    right: -6px;
    padding: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: crimson !important;
    border: 0 !important;
    text-align: center;

    i {
      font-size: 14px !important;
      color: $white !important;
      margin-right: 0;
    }
  }

  img {
    height: 20px;
    vertical-align: -4px;
    margin-left: 5px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.46em;
    bottom: -2em;
    left: 46%;
    box-sizing: border-box;
    border: 1em solid black;
    border-color: transparent transparent $white $white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -2px 2px 3px 0 rgba($black, 0.12);
  }
}

.sectionHeader {
  font-size: 16px;
  font-weight: 600;
  color: $grey;
  letter-spacing: 0.5px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.secureCheckoutTxt {
  font-size: 10px;
  font-weight: 300;
  color: $mediumGray;
  margin-bottom: 0;
}

.secureCheckoutTxtWrap {
  display: flex;
  margin: 0 0 12px 0 !important;
  align-items: center;

  p {
    margin-left: 10px;
  }
}

.customPadding {
  padding: 0 15px;
}

.shieldIcon {
  margin: 15px auto 3px;
  display: block;
  height: 26px;
}

.fullySecureTxt {
  font-size: 14px;
  font-weight: 500;
  color: $theme-color;
  margin-bottom: 15px;
  text-align: center;
}

.paymentRadioBtn {
  margin-bottom: 15px;

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0.13rem;
  }
}

.customInputGroup {
  background-color: $theme-color;
  border-color: $theme-color;
  color: $white;
  width: 40px;
}

.input-group > .form-control {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 0;

  &:focus {
    border: 1px solid $theme-color;
  }
}

.input-group-text {
  border-radius: 8px;
  justify-content: center;
}

.audioLocked {
  filter: blur(20px);
}

.audioWrapper {
  height: 260px;
  width: 100%;

  img {
    height: 35px !important;
    width: initial;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  audio {
    width: 90% !important;
    display: block;
    margin: 0 auto;
    height: 44px !important;
    max-width: 500px;
  }

  .audioRipple {
    width: 180px;
    height: 180px;
    border: 5px solid rgba($black, 0.02);
    display: block;
    margin: 30px auto;
    border-radius: 50%;
    position: relative;
  }

  .audioRipple:before {
    content: "";
    position: absolute;
    bottom: 10px;
    top: 10px;
    left: 10px;
    right: 10px;
    border: 5px solid rgba($black, 0.04);
    border-radius: inherit;
  }

  .audioRipple:after {
    content: "";
    position: absolute;
    bottom: 40px;
    top: 40px;
    left: 40px;
    right: 40px;
    border: 5px solid rgba($black, 0.12);
    border-radius: inherit;
  }

  .audioRippleStart {
    animation: pulse 1.25s infinite linear;
  }

  .audioRippleStart:before {
    animation: pulse 1.25s infinite linear;
  }

  .audioRippleStart:after {
    animation: pulse 1.25s infinite linear;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.75em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 1.25em rgba(var(--primary-color-rgb), 0.2);
  }
  100% {
    box-shadow: 0 0 0 0.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.75em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 1.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 2em rgba(var(--primary-color-rgb), 0);
  }
}

.highlightRow {
  background-color: rgba(var(--primary-color-rgb), 0.12) !important;
}

.form-error {
  display: block !important;
  color: $error;
  font-size: 13px;
  padding-left: 5px;
  margin: 3px 0 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.disabled-feed-options {
  pointer-events: none;
}

.greyCheck {
  color: $lightGray;
  font-size: 13px;
}

.seenTick {
  color: $theme-color;
  font-size: 13px;
}

.mediaCountCarousel {
  height: 21px;
  line-height: 21px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: $white;
  background: $grey;
  position: absolute;
  top: 12px;
  left: 4px;
  border-radius: 10px;
  padding: 0 12px;
}

.customModalFooter {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.customNotificationText {
  padding: 15px 10px;
  border-radius: 8px;
  background-color: $white !important;
  border: 1px dashed $theme-color !important;
  font-size: 14px;
  color: $grey !important;
  box-shadow: none !important;
  font-weight: 500;
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
}

.searchCreators {
  margin-top: 25px;
  margin-bottom: 12px;
}

.liveEventWrapper {
  margin-left: 0;
  margin-right: 0;
  background: $white;
  border-radius: 10px;
}

.ppvDetailsWrap {
  width: 100%;
  margin-top: 25px;
}

.shoutOutWrap {
  background: $white;
  border-radius: 12px;
  padding: 12px 12px 10px;
  margin-top: 20px;

  .searchChatListWrap {
    margin-bottom: 12px;
  }
}

.vaultFolderDetailsWrap {
  background: $white;
  border-radius: 12px;
  padding: 1px 15px;
}

.shoutOutMediaWrapCol {
  margin-bottom: 20px;

  &:nth-child(1) {
    padding-left: 0;
    padding-right: 6px;
  }

  &:nth-child(2) {
    padding-left: 6px;
    padding-right: 6px;
  }

  &:nth-child(3) {
    padding-left: 6px;
    padding-right: 0;
  }
}

.notificationContentWrap {
  padding: 15px 12px;
  background-color: $white;
  border-radius: 10px;
}

.filterBtnInfluencerDetails {
  background-color: rgba(var(--primary-color-rgb), 0.2) !important;
  border-radius: 25px;
  margin-top: 10px;
}

.customTable {
  th,
  td {
    border: 0 !important;
  }

  th {
    color: $grey;
    font-size: 15px;
    font-weight: 600;
  }

  td {
    color: $mediumGray;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    color: $theme-color;
    text-decoration: none;
  }

  .creatorNameTable {
    text-decoration: none;
    color: #d72984;
    color: $theme-color;
  }
}

.customTabContentInfluencerDetails {
  padding: 0 10px;
}

.screenshotAlertWrap {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .fa {
    color: #ff9999;
    font-size: 175px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: $grey;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: $lightGray;
    max-width: 500px;
    text-align: center;
    line-height: 1.6;
  }

  .termsBtn {
    margin-top: 5px;
    color: $theme-color !important;
    font-size: 12px;
    text-decoration: none !important;
    font-weight: 500;
  }
}

.typeMsgWrap,
.commentSection,
.commentShoutout,
.commentArea-liveStream,
.comments-Post {
  textarea {
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;
    appearance: none;
    -webkit-appearance: none;
  }

  textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  textarea::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.customSidebarWrap {
  .customSidebar {
    background-color: $white;
    padding: 10px 15px;
    position: fixed;
    z-index: 999;
    height: calc(100vh - 50px);
    overflow: auto;
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;

    ul {
      margin-bottom: 130px;

      a,
      li {
        border: 0;
        padding: 11px 0;
        display: flex;
        font-size: 14px;
        color: $grey;
        font-weight: 500;
        text-decoration: none;
        background-color: transparent;
        position: relative;
        cursor: pointer;

        span {
          width: 20px;
          margin-right: 15px;
          height: 20px;
        }

        img {
          height: 21px;
          margin-top: -4px;
        }

        i {
          font-size: 20px;
          color: #666;
          margin-top: 0;
        }
      }

      a.active {
        color: $theme-color;
      }

      li.active {
        color: $theme-color;
      }

      .customTopBorder:before {
        content: "";
        height: 3px;
        width: 200px;
        position: absolute;
        background-color: #e5e5e5;
        border-radius: 100px;
        top: -17px;
        left: 0;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }
  }

  .showSideMenu .customSidebar {
    width: 250px;
    transition: all 0.3s;
  }

  .hideSideMenu .customSidebar {
    margin-left: -250px;
    transition: all 0.3s;
  }
}

.customSidebar {
  .imagePreview {
    border-width: 2px;

    i {
      color: #818181;
      font-size: 40px;
      margin-top: 0;
    }
  }
}

.customMarginFaq {
  margin-left: 0;
  margin-right: 0;
}

// .showSideMenu + .main:before {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 998;
//   width: 100%;
//   height: 100vh;
//   content: "";
//   background: rgba($black, 0.7);
//   animation: opacity 0.3s;
// }

.showSideMenu .sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100vh;
  content: "";
  background: rgba($black, 0.7);
  animation: opacity 0.3s;
}

.bottomMenu {
  padding: 0 20px;
  height: 50px;
  width: 100%;
  background-color: $white;
  border-bottom: 2px solid #f0f0f0;
  top: 0;

  img {
    height: 22px;
  }

  ul {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  .nav-item {
    cursor: pointer;

    .activeIcon {
      display: none;
    }

    .inactiveIcon {
      display: initial;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .nav-item.active {
    .activeIcon {
      display: initial;
      fill: $theme-color;
    }

    .inactiveIcon {
      display: none;
    }

    svg {
      fill: $theme-color;
    }
  }
}

.app-header .navbar-brand {
  display: none;
}

.profileBackBtn {
  position: absolute;
  left: 8px;
  top: 15px;
  z-index: 99;
}

.loaderWrap {
  width: 300px;
  height: 250px;
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 10px;
    color: $grey;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.transactionTxt,
.transactionAmt {
  font-weight: 600;
  font-size: 14px;
  color: $grey;
  margin-top: 15px;
  text-align: center;
  margin-bottom: 0;
}

.transactionStatus {
  background: rgba(39, 174, 96, 0.12);
  border-radius: 30px;
  display: block;
  margin: 15px auto;
  max-width: 200px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  font-weight: 500;
  font-size: 14px;
  color: #27ae60;

  i {
    font-size: 16px;
    margin-right: 3px;
  }
}

.transactionAmt {
  font-weight: 500;
  color: $theme-color;
  margin-top: 10px;
}

.transactionDetails {
  li {
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-weight: 600;
    font-size: 14px;
    color: $grey;

    div {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      font-weight: 400;
    }

    .btn {
      padding: 0 2px;
      color: $theme-color;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.bundleOptions {
  margin-top: 8px;
  margin-bottom: 15px;

  .custom-control-label::before,
  .custom-control-label::after {
    top: 2px;
  }
}

.stickerLiveStreaming {
  height: 70px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.stickerImg {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto 30px;
}

.stickerOptions {
  display: flex;
  overflow-x: auto;
  margin-left: 40px;
  margin-top: 6px;
  scrollbar-color: transparent transparent;
  padding-bottom: 3px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }

  .btn {
    padding: 0;
    height: 34px;
    width: 34px;
    min-width: 34px;
    min-height: 34px;
    margin-right: 7px;
    border-radius: 50%;

    img {
      height: 34px;
      width: 34px;
      min-width: 34px;
    }
  }
}

.unlockAccess {
  margin-top: 10px;

  h5 {
    font-size: 16px;
    font-weight: 600;
    color: $grey;
    margin-bottom: 8px;
  }

  p {
    color: $mediumGray;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .themeBtn {
    margin: 30px auto 15px;
    display: block;
  }
}

.unlockAccessCustomList {
  margin-top: 10px;

  li {
    color: $mediumGray;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    border: 0;
    padding: 2px 0;
    list-style-type: circle;
    list-style-position: inside;
    display: list-item;
  }
}

.unlockAccessCharges {
  li {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
  }
}

.expandIcon {
  position: absolute;
  z-index: 99;
  left: 15px;
  top: 10px;
  height: 20px;
  cursor: pointer;
}

.viewBtn {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 0;
  margin-top: 0;
}

.otherPgsProfileID {
  color: $theme-color !important;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}

.cardFeed .card-header .publicFeedProfileID {
  padding: 0;

  &:hover {
    text-decoration: underline !important;
    color: $theme-color !important;
  }
}

.policyBtn {
  width: 100%;
  padding: 0;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $grey !important;
  font-weight: 500;
  font-size: 14px;

  span {
    width: 19px !important;
    // min-width: 19px;
    display: inline-block;
    text-align: left;
  }

  i {
    font-size: 14px !important;
    transition: all 0.3s;
  }

  .rotateIcon {
    transform: rotate(90deg);
    transition: all 0.3s;
  }
}

.hideCarouselIndicator {
  .carousel-indicators {
    display: none;
  }
}

.processingContent {
  height: 300px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $mediumGray;

  .btn {
    background: $theme-color !important;
    border: 0;
    box-shadow: none !important;
    color: $white !important;
    font-weight: 500;
    height: 40px;
    width: 120px;
    padding: 0 10px;
    border-radius: 20px;
  }
}

.singleDateWrap {
  .SingleDatePicker,
  .SingleDatePickerInput__withBorder,
  .DateInput {
    width: 100%;
  }

  .SingleDatePickerInput__withBorder {
    border: 0;
  }
}

.dobWrap {
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: flex-start;
  }
}

.goalDataWrap {
  margin-top: 12px;
  font-size: 14px;
  color: $mediumGray;

  p {
    margin-bottom: 3px;
  }

  .goalStatus {
    margin-top: 7px;
    font-weight: 500;
    display: flex;
  }

  .progress {
    height: 15px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;
    background: $white;
  }

  .progress-bar {
    background-color: $theme-color;
  }

  .btn {
    background-color: $theme-color !important;
    border: 0 !important;
    border-radius: 20px;
    height: 32px;
    padding: 0 15px;
    color: $white !important;
    font-size: 14px;
    margin-left: auto;
    line-height: 32px;
    font-weight: 500;
  }
}

.mediaBtn {
  border-radius: 50%;
  padding: 0;
  height: 30px;
  width: 30px;
  margin-right: 12px;
  margin-bottom: 0;
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 21px;
  }

  .videoIcon {
    height: 20px;
  }

  .audioIcon {
    height: 22px;
  }

  .priceIcon {
    height: 23px;
    margin-top: -1px;
  }
}

.voiceVideoMsg {
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 3px;
  color: $white;
  background-color: $theme-color;
  font-size: 14px;

  div {
    font-weight: 600;
    font-style: italic;
    margin-top: 3px;
  }

  & + .themeBtn {
    line-height: 43px;
    margin-top: 3px;
    margin-bottom: 3px;
    max-width: 150px;
    font-size: 14px;
  }
}

.callInfoWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 999;

  div {
    height: 28px;
    font-size: 14px;
    border-radius: 6px;
    padding-left: 7px;
    padding-right: 7px;
    line-height: 28px;
    background: $theme-color;
    color: $white;
    font-weight: 500;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  }
}

.turnedOffWrap {
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 9;
}

.turnedOff {
  font-size: 12px;
  color: $white;
  height: 30px;
  font-size: 12px;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  line-height: 30px;
  background: #f8d7da;
  color: #721c24;
  margin-bottom: 10px;
}

.tipVault {
  display: none;
}

.tipVaultFile {
  background-color: transparent !important;
  padding: 0 2px;
  height: 26px;
  line-height: 26px;
  border: 0 !important;
  position: absolute;
  top: 8px;
  right: 9px;
  z-index: 999;

  img {
    height: 24px;
  }
}

.tagNamePost {
  font-weight: 400;
  color: $theme-color !important;

  &:hover {
    text-decoration: none;
  }
}

.mediaLibraryFilesWrap {
  position: relative;
  cursor: pointer;
  margin: 4px 0;
  overflow: hidden;
  height: 152px;

  .fa {
    color: $theme-color;
    font-size: 25px;
    float: right;
    background-color: $white;
    border-radius: 50%;
    line-height: 19px;
    width: 19px;
  }

  .mediaFileSelected {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba($black, 0.3);
    border-radius: 10px;
    padding: 10px 12px;
  }
}

.mediaLibraryFiles {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  height: 100%;
  background: #fafafa;
  border: 1px solid rgba($black, 0.04);

  audio {
    width: 95% !important;
    height: 54px !important;
  }
}

.mediaLibraryMsgPg {
  margin-left: -4px;
  margin-right: -4px;

  .notificationDay ~ .mediaLibraryFilesMsg {
    border-radius: 8px;
    height: 85px;
    width: calc(16.66% - 6px);
    border: 1px solid rgba($black, 0.04);
    overflow: hidden;
    margin: 4px 3px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    video {
      background-color: #111;
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .audioImg {
      height: 40px;
      width: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .videoIcon {
      position: absolute;
      bottom: 5px;
      left: 7px;
      color: $white;
      font-size: 14px;
      z-index: 99;
    }

    .mediaPrice,
    .mediaHasPurchased {
      background: $theme-color;
      color: $white;
      border-radius: 4px;
      cursor: text;
      padding: 0 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      height: 16px;
      position: absolute;
      left: 4px;
      top: 4px;
      z-index: 99;
    }

    .mediaHasPurchased {
      right: 4px;
      left: unset;
    }
  }

  .notificationDay {
    padding-left: 6px;

    &:first-child {
      margin-top: 0;
    }
  }

  .mediaLibrarySpinner {
    display: block;
    text-align: center;
  }

  .blurPreview {
    img,
    video,
    .audioImg {
      filter: blur(8px);
    }

    .mediaLock {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;

      .fa {
        font-size: 30px;
        color: $white;
      }
    }
  }
}

.seeMedia {
  font-size: 12px;
  color: $theme-color !important;
  padding: 0 12px;
  font-weight: 400;
  margin-top: 1px;
  text-transform: uppercase;
  text-decoration: none !important;
  font-weight: 500;
}

.reloadBtn {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 0 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -38%);
  z-index: 99;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: $lightGray !important;
}

.mediaLibraryFilesWrap {
  .reloadBtn {
    i {
      font-size: 20px;
      line-height: unset;
      width: unset;
      color: $lightGray !important;
      float: unset;
      background-color: transparent;
      border-radius: unset;
    }
  }
}

// my order
.myOrderItem {
  background-color: $white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;

  .orderStatus {
    display: flex;
    align-items: center;

    .statusIcon {
      position: relative;
      margin-right: 10px;

      i {
        color: #f5f5f5;

        &.fa-square {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $lightGray;
          border-radius: 50%;
          font-size: 12px;
        }

        &.fa-check {
          position: absolute;
          top: 22px;
          right: 0;
          width: 14px;
          height: 14px;
          font-size: 10px;
          background-color: #03a685;
          border-radius: 50%;
          line-height: 14px;
          text-align: center;
        }
      }
    }

    .status {
      h2 {
        font-size: 14px;
        margin-bottom: 1px;

        &.delivered {
          color: #03a685;
        }
      }

      p {
        font-size: 12px;
        color: $lightGray;
        margin-bottom: 0;
      }
    }
  }

  .orderProduct {
    display: flex;
    margin-top: 20px;

    .orderImage {
      width: 100px;
      height: 100px;
      min-width: 100px;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .orderContent {
      padding-left: 15px;

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 7px;
        color: $grey;
      }

      ul {
        list-style-type: none;

        li {
          border: 0;
          border-right: 1px solid rgba($black, 0.15);
          padding: 0;
          padding-right: 10px;
          margin-right: 10px;
          color: $lightGray;

          &:last-child {
            border-right: none;
          }

          span {
            color: $mediumGray;
            font-weight: 500;

            &:last-child {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }

  .orderActionButton {
    display: flex;
    margin-top: 12px;

    button {
      height: 34px;
      line-height: 32px;
      font-size: 14px;
      border-radius: 25px;
      padding: 0 15px;
      margin: 0;
    }
  }

  .productReview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    border-top: 1px solid $white;
    padding: 10px;

    .reviewStar {
      i {
        color: #c1c1c1;
        font-size: 16px;
        margin-right: 2px;
        &.active {
          color: $theme-color;
        }
      }
    }
    .reivewAction {
      color: $theme-color;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

// courses pg
.modulesInfoWrap {
  border-radius: 12px;
  border: 0;

  .card-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 15px 13px;
    border-bottom: 1px solid rgba($black, 0.04);
    background: $white;
  }

  .card-body {
    padding: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }

  ul li {
    border: 0;
    border-radius: 0 !important;
    padding: 21px 15px 22px 30px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba($black, 0.04);
    list-style-type: none;
    counter-increment: auto-increment-numbers;

    &::before {
      content: counter(auto-increment-numbers);
      position: absolute;
      top: 19px;
      left: 15px;
      font-size: 14px;
      font-weight: 500;
    }

    &:last-child {
      border: 0 !important;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      font-size: 12px;
      color: $lightGray;

      &:nth-child(2) {
        font-weight: 500;
      }
    }
  }

  .completionIndicator {
    display: flex;
    margin: 2px -3px 0 -3px;

    span {
      display: inline-flex;
      height: 4px;
      border-radius: 2px;
      background-color: #f5f5f5;
      flex: 1;
      margin: 0 3px;
    }

    .completion {
      background-color: $theme-color;
    }
  }

  .subModuleCollapseHead {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid rgba($black, 0.04);
    align-items: flex-start;

    &:last-child {
      border-bottom: 0;
    }

    &:last-child {
      border-bottom: 0 !important;
    }

    span {
      color: $mediumGray;
    }

    i {
      cursor: pointer;
      color: $lightGray;
      font-size: 12px;
      transition: all 0.3s;
      vertical-align: 0.5px;
    }

    .rotateIcon {
      transform: rotate(180deg);
      transition: all 0.3s;
      vertical-align: -1px;
    }
  }

  .subModuleCollapseBody {
    padding: 15px 25px;
    border-bottom: 1px solid rgba($black, 0.04);

    label {
      font-weight: 400;
    }

    .quizQues {
      font-weight: 500;
      margin-top: 8px;
    }
  }
}

.tabsCourses {
  padding: 15px 15px 0;
  background-color: $white;
  border-radius: 12px;
  margin-bottom: 25px;

  .nav-item {
    flex-grow: 0;
  }

  .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .tab-content {
    border: 0;
  }

  .tab-pane {
    padding: 20px 0;
  }
}

.msgPreviewWrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;
  margin-top: 10px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.msgPreview {
  border: 1px solid rgba($black, 0.08);
  border-radius: 8px;
  position: relative;
  margin: 8px 12px 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  font-size: 14px;
  height: 57px;
  width: 76px;
  min-width: 76px;
  span {
    padding: 6px;
    text-align: center;
    font-size: 14px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.tabContentMediaLibrary {
  border: 0;
  border-top: 1px solid #e6e6e6;

  .tab-pane {
    padding: 8px 0;
  }
}

.mediaFileUploadWrap {
  border: 1px dashed rgba($black, 0.15);
  border-radius: 10px;
  height: 152px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label.themeBtn {
    line-height: 42px !important;
  }
}

.createPostBtnsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.createPostBtnsWrap {
  display: flex;
  align-items: center;
}

.filesSelected-CreatePost {
  font-size: 14px;
  color: $mediumGray;
  margin-top: 8px;
  margin-bottom: 8px;
}

.mediaLibraryPagination {
  display: flex;
  justify-content: flex-end;

  .pagination {
    margin-bottom: 0;
    margin-top: 5px;
    display: flex;
    align-items: center;

    .page-link {
      border: 0;
    }

    .page-item.disabled .page-link {
      color: $mediumGray;
    }

    .page-link {
      color: $theme-color;
      box-shadow: none !important;
      font-weight: 400;
      margin-left: 2px;
      margin-right: 2px;
      height: 28px;
      width: 28px;
      line-height: 28px;
      padding: 0 1px;
      text-align: center;
      font-size: 12px;
      border-radius: 8px;
    }

    .page-item.active .page-link {
      background-color: $theme-color;
      color: $white;
      border-radius: 50%;
    }
  }
}

.filesSelectedTxt {
  display: flex;
  color: $lightGray;
  font-size: 14px;
}

@keyframes textcolor {
  from {
    color: rgba($mediumGray, 0.8);
  }
  to {
    color: green;
  }
}
@keyframes dotColor {
  from {
    background-color: rgba($mediumGray, 0.8);
  }
  to {
    background-color: green;
  }
}
@keyframes Canceltextcolor {
  from {
    color: rgba($mediumGray, 0.8);
  }
  to {
    color: $error;
  }
}
@keyframes CanceldotColor {
  from {
    background-color: rgba($mediumGray, 0.8);
  }
  to {
    background-color: $error;
  }
}

// product list page
.productCard {
  border: 0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

  .card-img {
    height: 180px;
    object-fit: cover;
    cursor: pointer;
  }

  .card-body {
    padding: 13px 10px;
  }

  .card-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .productInfoWrap {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: $lightGray;

    p {
      margin-bottom: 0;

      span {
        font-weight: 500;
      }
    }
  }
}

.customCategorySelect {
  input#ProductName {
    padding-left: 0;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    background-color: $inputBorder !important;
    border-color: $inputBorder !important;
    border-radius: 6px !important;
  }

  .css-1pahdxg-control {
    box-shadow: none;
    border-color: $theme-color !important;
  }

  .categoryOption {
    img {
      height: 28px;
      width: 28px;
      object-fit: cover;
      border-radius: 4px;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      color: $lightGray;
    }
  }

  #react-select-4-listbox {
    z-index: 99;
  }
}

.table {
  thead th {
    border: 0;
    font-size: 14px;
    color: $grey;
    padding: 10px;
    font-weight: 600;
  }

  tbody td {
    vertical-align: middle;
    font-size: 14px;
    color: $mediumGray;
    padding: 13px 10px;
    border-top: 0;
  }

  tbody td a {
    color: $theme-color;
  }
}

.customTableWrap {
  margin-top: 20px;
  border: 1px solid rgba(201, 201, 201, 0.4);
  border-radius: 20px;
  padding: 15px;
  background-color: $white;

  table {
    margin-bottom: 0;

    tbody tr td {
      border-top: 0;
      color: $grey;
    }

    .inStock {
      color: #388e3c;
    }

    .outStock {
      color: $error;
    }
  }
}

.uploadLogo {
  margin-bottom: 20px;

  p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .productLogoPreview {
    width: 100px;
    height: 100px;
    background: $white;
    border: 1px solid rgba($black, 0.1);
    border-radius: 50%;
    display: block;
    margin-bottom: 8px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    label {
      cursor: pointer;
      background-color: $theme-color !important;
      color: $white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 30px;
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
      right: -2px;
      text-align: center;
    }
  }

  small {
    color: $lightGray;
  }
}

.productDescription {
  .ql-editor {
    height: 130px;
    max-height: 130px;
    overflow: auto;
  }
}

.productThumbnail {
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin-right: 8px;
  border-radius: 6px;
  object-fit: cover;
}

.productFilterWrapper {
  display: flex;
  margin-top: 50px;
  padding: 0 5px;
  margin-bottom: -5px;

  .filter {
    margin-right: 25px;

    .btn-group {
      button {
        padding: 0px;
        border-radius: 50% !important;
        margin-right: 5px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        font-size: 14px;
        border-color: rgba($black, 0.08);

        &.active {
          border-color: $theme-color !important;
          color: $theme-color;
        }

        &.productSize {
          background-color: transparent !important;
          color: $grey;
          font-weight: 500;

          &.active {
            color: $white;
            background-color: $theme-color !important;
          }
        }
      }
    }
  }
}

.actionProductDetails {
  display: flex;

  .btn {
    background: $theme-color !important;
    height: 32px;
    border: 0;
    border-radius: 20px;
    color: $white !important;
    font-size: 12px;
    font-weight: 500;
    flex-shrink: 0;
  }
}

// quantity
.quantity {
  display: inline-flex;

  .btn-link {
    height: 32px;
    width: 30px;
    font-size: 14px;
    border-radius: 0;
    padding: 0;
    background-color: $white !important;
    color: $theme-color !important;
    border: 1px solid rgba($black, 0.15) !important;
    line-height: 30px;
  }

  input {
    width: 40px;
    height: 32px;
    text-align: center;
    border-radius: 0;
    background-color: $white !important;
    border-color: rgba($black, 0.15);
    border-left: 0;
    border-right: 0;
    line-height: 32px;
  }
}

// cart
.wishlistItem {
  display: flex;
  border: 1px solid rgba($black, 0.08);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;

  .remove {
    position: absolute;
    top: -10px;
    right: -10px;
    color: $error;
    background-color: $white !important;
    border-radius: 50px;
    font-size: 14px;
    padding: 0;
    width: 20px;
    height: 20px;
    border: 0;
    box-shadow: 0 1px 5px 0 rgba($black, 0.22) !important;
  }

  .productImgWrap {
    width: 120px;
    height: 120px;
    border: 1px solid rgba($black, 0.05);
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .productInfoCart {
    flex: 1;
    padding-left: 12px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .price {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      color: $mediumGray;

      .salePrice {
        font-size: 16px;
        font-weight: 500;
        color: $grey;
        margin-bottom: 0;
      }

      .productPrice {
        text-decoration: line-through;
        font-size: 12px;
        color: $lightGray;
        margin-left: 8px;
        margin-bottom: 0;
      }

      .off {
        font-size: 12px;
        font-weight: 500;
        margin-left: 8px;
        margin-bottom: 0;
      }
    }

    .detail {
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 5px;
        color: $lightGray;

        p {
          position: relative;
          font-size: 14px;
          margin-bottom: 0;

          &:first-child {
            min-width: 65px;
            display: inline-block;

            &::after {
              content: ":";
              position: absolute;
              right: 10px;
            }
          }

          &.outStock {
            color: $error;
          }
        }

        &:not(:first-child) {
          display: flex;
          .rating {
            margin-left: 10px;
            border-left: 1px solid rgba($black, 0.2);
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.priceDetails {
  border: 1px solid rgba($black, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 20px 10px;
  font-size: 18px;
  margin-bottom: 20px;

  span {
    font-weight: 500;
  }

  .themeBtn {
    display: block;
    margin: 22px auto 0;
  }

  ul {
    margin-top: 9px;

    li {
      padding: 10px 1px;
      border: 0;
      border-top: 1px solid rgba($black, 0.08) !important;
      border-radius: 0;
      font-weight: 400;
      font-size: 14px;
      color: $lightGray;
      display: flex;
      justify-content: space-between;

      span {
        font-weight: 500;
        color: $grey;
        flex-shrink: 0;
      }
    }
  }
}

// checkout
.paymentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
    font-size: 18px;
  }

  .themeBtn {
    width: auto;
    margin: 0;
    font-size: 14px;
  }
}

.addressLayout {
  border: 1px solid rgba($black, 0.1);
  border-radius: 8px;
  padding: 15px 12px;
  margin-bottom: 12px;

  .form-check {
    padding-left: 0;
  }

  .custom-control-label {
    cursor: pointer;
  }

  .locType {
    background-color: rgba($black, 0.1);
    color: $grey;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    margin-left: 5px;
    text-transform: capitalize;
  }

  .btn {
    color: $theme-color;
    margin-left: auto;
    padding: 0 5px;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: $mediumGray;
  }
}

.productCarousel {
  .carousel .carousel-item img,
  .carousel .carousel-item video {
    object-fit: contain;
  }
}

.videoQuality {
  position: absolute;
  right: 6px;
  bottom: 67px;
  display: none;

  .btn.btn-secondary {
    background-color: rgba($black, 0.65) !important;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    img {
      height: 14px;
    }
  }

  .dropdown-item {
    padding: 8px 10px 4px;
  }

  .dropdown-menu {
    top: 0 !important;
    right: 0 !important;
    transform: translate3d(0px, -137px, 0px) !important;
  }

  .custom-control-label {
    font-size: 12px;

    &::before,
    &::after {
      top: 0px;
    }
  }
}

.portraitVideoWrap,
.landscapeVideoWrap,
.squareVideoWrap {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 */

  .videoPreview {
    height: 100%;
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  &:hover {
    .videoQuality {
      display: initial;
    }
  }
}

.landscapeVideoWrap {
  padding-top: 56.25%; /* 16 / 9 = 1.7777 */

  video {
    object-fit: cover;
  }
}

.M4HRY2n7rpNAd1UjDNZe {
  display: none !important;
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 62px;
  z-index: 999;
  height: 50px;
  background-color: $theme-color;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  #uploadProgressHeader {
    color: $white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}

.custom-switch {
  .custom-control-label::after,
  .custom-control-input:checked ~ .custom-control-label::after {
    top: 4px;
  }
}

.liveEventCard {
  margin: 10px auto;

  .mediaPhotoWrap-Feed,
  .mediaPhoto-Feed,
  .shadowOverlayImg {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  video {
    border-radius: 0;
    display: block;
  }
}

.textWarning {
  color: $error !important;
}

.transactionCost {
  margin-top: 15px;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
}

// live streaming new
.myCallContainer {
  width: 100%;
  height: 100%;

  .zegoUserVideo_click .zegoUserVideo_click .zegoUserVideo_click {
    font-size: 50px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    width: 120px !important;
    height: 100px !important;
    border-radius: 18px !important;
    color: $theme-color !important;
  }

  .zegoUserVideo_click {
    align-items: center;
    border-radius: 0 !important;

    font-size: 18px !important;

    i {
      width: 10vmin !important;
      height: 10vmin !important;
    }

    & + div {
      div {
        height: unset !important;
        border-radius: 10px !important;
        padding: 3px 10px !important;
        max-width: 100% !important;

        p,
        span {
          margin-bottom: 0;
          font-size: 14px !important;
        }

        // span {
        //   width: 3.5vmin !important;
        //   height: 3.5vmin !important;
        // }

        span + span {
          height: 22px !important;
          width: 22px !important;
        }
      }
    }
  }

  .t7CkP1l2e1KLqty5_18v .CTk5yvs1VFTUR3zVNAE8 {
    left: 10px !important;
    top: 50px !important;
    bottom: unset !important;
    right: unset !important;
    width: max-content !important;
    padding: 0 !important;

    div {
      max-width: unset !important;
    }

    .unmuteVideo {
      margin-bottom: 0 !important;
      font-size: 14px !important;

      & + span {
        width: 4vmin !important;
        height: 4vmin !important;
      }
    }
  }

  .ZegoRoomMobile_ZegoRoom .sKtK1LFA_jOcb1MuqFNo {
    a {
      background-size: 35px 35px !important;
      width: 48px !important;
      height: 45px !important;
      border-radius: 10px !important;
      cursor: pointer;
    }

    #ZegoLiveButton {
      width: unset !important;
      font-size: 18px !important;
      padding: 0 8px !important;
      background: $theme-color !important;
    }
  }

  .sKtK1LFA_jOcb1MuqFNo {
    height: unset !important;
    padding: 15px 10px !important;
  }

  #ZegoRoomCssMobilePopMore {
    padding: 10px !important;
    bottom: 12% !important;
    right: 25% !important;
    border-radius: 15px !important;

    .dxks8Oo_gSMcyB4NAUS5 {
      div {
        background-image: none !important;
        height: unset !important;
        width: unset !important;
        margin-right: 20px !important;
        margin-left: 20px !important;

        i {
          background-size: 35px !important;
          height: 40px !important;
          width: 40px !important;
          border-radius: 8px !important;
          margin-bottom: 25px !important;
        }

        span {
          font-size: 12px !important;
        }
      }

      & + div {
        bottom: 70px !important;
        right: 25% !important;
      }
    }
  }

  .unmuteVideo {
    margin-bottom: 0 !important;
  }
}

// custom video player
.customVideoWrapper {
  position: relative;
  overflow: hidden;

  .videoControlsWrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 9px 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.25s linear;
    background: linear-gradient(to top, rgba($black, 0.25), rgba($black, 0.02));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .videoSeekBarWrap {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
  }

  .videoSeekBar {
    background: $lightGray;
    cursor: pointer;
    width: 100%;
    height: 4px;
    border-radius: 20px;
  }

  .dragHandle {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0;
    background: transparent;
    border: 0 !important;
    height: unset;
    position: absolute;
    top: -5px;
    left: 0;

    &::-webkit-slider-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
    }
  }

  .videoProgress {
    background: $theme-color;
    height: 100%;
    transition: all 0.3s;
    border-radius: 20px;
  }

  .videoControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    > div {
      display: flex;
      align-items: center;
    }

    .btn {
      width: 33px;
      height: 33px;
      padding: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 29px;
      color: $white;
      text-decoration: none !important;
      margin: 0 5px;
      @include ripple;
      border-radius: 6px;

      img {
        height: 32px;
        width: unset;
        object-fit: contain;
      }
    }
  }

  .videoTimestamp {
    font-size: 14px;
    color: $white;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 3px;
  }

  .videoVolume {
    display: flex;
    align-items: center;
    position: relative;

    .dragHandle {
      width: 70px;
      top: 10px;
      right: 43px;
      left: unset;
      border-radius: 20px;
    }

    .videoSeekBar {
      width: 70px;
      display: none;
      transition: all 0.3s;
    }

    &:hover .videoSeekBar {
      display: block;
      transition: all 0.3s;
    }
  }

  &.nowPlaying {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: #111;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    min-height: 300px;
  }

  .closeVideo,
  .enlargeVideo,
  .dragVideo {
    position: absolute;
    right: 7px;
    top: 5px;
    transform: translateY(-150%);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 29px;
    height: 29px;
    text-align: center;
    padding: 0;
    text-decoration: none;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    transition: transform 0.25s linear;
  }

  .enlargeVideo {
    right: unset;
    left: 7px;

    img {
      height: 18px;
      width: unset;
      object-fit: contain;
    }
  }

  .dragVideo {
    cursor: grab !important;
    right: unset;
    left: 50px;
    font-size: 20px;

    &:active {
      cursor: grabbing !important;
    }
  }

  .playPauseVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;
    text-decoration: none;
    transition: all 0.3s;

    img {
      height: 42px;
      object-fit: contain;
      width: unset;
    }
  }

  .playingVideo {
    display: none;
    transition: all 0.3s;
  }

  .videoSettings {
    position: absolute;
    bottom: 53px;
    background: $white;
    border-radius: 6px;
    right: 15px;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  }

  &.fullscreen {
    position: fixed;
    inset: 0;
    z-index: 1031;
    max-height: unset;

    video {
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }

    .videoControlsWrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .videoControls .btn {
      margin: 0 7px !important;
    }

    .videoVolume {
      .dragHandle {
        right: 47px;
      }
    }
  }

  &.fullscreen.landscapeVideoWrap,
  &.fullscreen.portraitVideoWrap,
  &.fullscreen.squareVideoWrap,
  &.floating.landscapeVideoWrap,
  &.floating.portraitVideoWrap,
  &.floating.squareVideoWrap {
    padding-top: 0;
  }

  .theatre {
    border-radius: 0;
  }

  &.fullscreen.landscapeVideoWrap {
    video {
      object-fit: contain;
    }
  }

  &.floating {
    position: fixed;
    z-index: 100;
    bottom: 15px;
    right: 15px;
    width: 350px !important;
    height: 196.875px !important;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);

    video {
      height: 100%;
      display: block;
      border-radius: 16px;
    }

    .videoTimestamp {
      display: none;
    }

    &:not(.react-draggable-dragging) {
      -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
      transition: transform 0.5s ease-out;
    }
  }

  &:hover {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .closeVideo,
    .enlargeVideo,
    .dragVideo {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .playingVideo {
      display: flex;
      transition: all 0.3s;
    }
  }

  &:focus {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }
  }
}
// custom video player

.newMessageWrap {
  padding: 10px 10px 1px;
  background-color: $white;
  border-radius: 10px;
}

.showMediaModal {
  video {
    height: 100%;
    width: 100%;
    background-color: transparent;
  }
}

.renewSubscriptionCard {
  border: 1px solid $theme-color;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
}

.usernameTopCoverImg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba($black, 0.04),
    rgba($black, 0.06),
    rgba($black, 0.08)
  );
  z-index: 20;
  padding: 10px;
  font-size: 16px !important;
}

.influencerVaultSection {
  height: 400px;
  overflow: auto;
  // padding: 10px 20px;
}

.searchResultsTitle {
  font-size: 14px;
  text-transform: uppercase;
  color: $lightGray;
  margin-bottom: 2px;
  margin-top: 15px;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
}

.searchResultsNew {
  border-radius: 4px !important;
  padding: 0;
  border: 0;
  margin-top: 7px;
  margin-bottom: 7px !important;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  width: 100%;

  .infCoverImg {
    object-fit: none;
    height: 200px;
    width: 100%;
  }

  .imgCropWrap {
    position: absolute;
    width: 100%;

    padding-top: 33.3333333%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      height: 100%;
      width: 100%;

      &.widthAuto {
        // if width > height
        width: auto;
        height: 100%;
      }

      &.heightAuto {
        // if height > width or height = width
        width: 100%;
        height: auto;
      }
    }
  }
}

.searchResultsContentNew {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  border-image: fill 0 linear-gradient(rgba($black, 0.5), rgba($black, 0.55));
  height: 100%;
  width: 100%;
  padding: 10px;
  color: $white;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;

  p {
    margin-bottom: 0;
    font-weight: 500;
  }

  img {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    border: 3px solid $white;
    object-fit: cover;
    background-color: $white;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .btn {
    padding: 0;
    color: $white;
    font-size: 14px;
  }

  h4 + .btn {
    font-size: 14px;
  }

  ul {
    padding-left: 90px;
    text-align: center;
  }

  li {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: 400;
    flex: 1;
    font-size: 14px;
    padding-left: 3px;
    padding-right: 3px;

    &:not(:first-child) {
      border-left: 1px solid rgba($white, 0.4) !important;
    }
  }
}

.customPushNotification {
  display: flex;
  background-color: rgba(var(--primary-color-rgb), 0.2);
  border-color: $theme-color;
  padding: 18px 12px;
  width: 100%;
  max-width: 660px;
  margin: 10px auto !important;

  h4 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
    color: $grey;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 8px;
    color: $lightGray;

    .btn {
      padding: 0 2px 1px;
      font-size: 14px;
      color: $theme-color;
      text-decoration: none !important;
    }
  }

  img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-right: 12px;
    min-width: 80px;
    background-color: white;
    border-radius: 6px;
    padding: 2px;
  }

  svg {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-right: 12px;
    min-width: 80px;
    background-color: white;
    border-radius: 6px;
    padding: 2px;
  }

  .close {
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    height: 24px;
    width: 24px;
    padding: 0 0 0 0.5px;
    font-size: 20px;
    color: $white;
    line-height: 24px;
  }
}

.defaultCoverImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }
}

.creatorCategory {
  display: flex;
  // flex-wrap: wrap;
  margin-bottom: 8px;
  padding-block: 3px;
  overflow-x: auto;

  .btn {
    border-radius: 25px;
    background-color: $white;
    border: 1px solid transparent;
    font-size: 14px;
    color: $grey;
    box-shadow: 0px 1px 0.5px 1.5px rgba($black, 0.06) !important;
    margin-right: 6px;
    margin-bottom: 10px;
    padding: 0 12px;
    height: 38px;
    min-width: 62px;
    line-height: 37px;
    flex-shrink: 0;

    &:first-child {
      margin-left: 2px;
    }

    &.btn-secondary:not(:disabled):not(.disabled):active {
      background-color: $white;
      border-color: transparent;
    }

    svg {
      height: 28px;
      width: 28px;
      fill: $grey;
      margin-right: 7px;
      margin-top: -1px;
    }

    .gamingIcon {
      vertical-align: -9px;
    }

    &.active {
      background-color: $white !important;
      color: $theme-color !important;
      border: 1px solid $theme-color !important;

      svg {
        fill: $theme-color;
      }
    }
  }

  .clearCategory {
    border-color: rgba($error, 0.8) !important;
    color: rgba($error, 0.8) !important;
    box-shadow: none !important;
  }
}

.sharedPostHighLighted .cardFeed {
  box-shadow: 0px 0px 25px -5px rgba(var(--primary-color-rgb), 0.7);
  padding: 3px 10px 10px;
}

.imgQuality {
  .addBtn {
    padding: 0 10px;
    position: absolute;
    top: 9.5px;
    right: 45px;
    z-index: 9;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
  }
}

// .imgQualityPopover .popover {
//   transform: unset !important;
//   top: 48px !important;
//   right: 75px !important;
//   left: unset !important;
// }

.subscriptionList {
  margin-top: 0;
}

.badgeUnlocked {
  border-radius: 10px;
  border: 1px solid $theme-color;
  padding: 15px 5px;
  text-align: center;
  margin-block: 15px;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1);

  img,
  svg {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  svg {
    fill: $theme-color;
  }

  p {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    color: $black;
  }
}

.badgeLocked {
  border: 1px dashed rgba($black, 0.3);
  background-color: rgba($black, 0.4);
  opacity: 0.8;
  box-shadow: none;
  position: relative;

  p {
    color: $mediumGray;
  }
}

.storyCarousel {
  margin: 25px auto;

  .react-multi-carousel-item {
    .storyImgWrap {
      width: 90px;
      height: 90px;
      padding: 4px;
      border: 2px solid $theme-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      img {
        height: 78px;
        width: 78px;
        object-fit: cover;
        border-radius: 50%;
      }

      .fa {
        font-size: 32px;
        color: $theme-color;
        margin-top: 2px;
      }

      .liveEventInStory {
        position: absolute;
        bottom: -1px;
        left: 27px;
        background-color: $theme-color;
        color: $white;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        height: 15px;
        line-height: 15px;
        padding: 0 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:has(.liveEventInStory) {
        border: 2px solid transparent;
        background: linear-gradient(white, white),
          linear-gradient(to right, $theme-color, #f03f9f 33%, #db2782 66%);
        background-clip: padding-box, border-box; /* Gradient only on the border */
        background-origin: border-box;
      }
    }

    p {
      display: block;
      font-size: 12px;
      margin-block: 7px 0;
      width: 85px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .react-multiple-carousel__arrow {
    min-width: 35px;
    min-height: 35px;
    z-index: 900;
  }
}

// .viewStoryModal {
//   .carousel {
//     .viewStory {
//       height: 100%;
//       width: 100%;
//       max-width: 500px;
//       margin-inline: auto;
//       position: relative;

//       img,
//       video {
//         height: 100%;
//         width: 100%;
//         object-fit: contain;
//       }

//       .delStory {
//         position: absolute;
//         color: #ff2323;
//         padding: 0;
//         top: 12px;
//         right: 5px;
//         height: 23px;
//         width: 23px;
//         line-height: 21px;
//         font-size: 14px;
//         border-radius: 50%;
//         background-color: $white;
//       }

//       .storyText {
//         position: absolute;
//         width: 100%;
//         bottom: 0;
//         left: 0;
//         padding-block: 15px;
//         background: linear-gradient(
//           to top,
//           rgba($black, 0.7),
//           rgba($black, 0.35)
//         );

//         p {
//           font-size: 14px;
//           color: $white;
//           margin-bottom: 0;
//         }
//       }

//       .creatorInfoInsideStory {
//         @extend .storyText;
//         bottom: unset;
//         top: 0;
//         display: flex;
//         justify-content: center;
//         padding-block: 5px;

//         p {
//           font-size: 12px;
//         }

//         .btn-link {
//           height: 24px;
//           padding: 0;
//           font-size: 11px;
//           color: $white;
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// }

.advertiseArea {
  position: sticky;
  top: 0px;
  width: calc(min(25.5vw, 300px));
  overflow: auto;
  height: calc(100vh - 50px);
  padding-top: 10px;
  padding-right: 5px;
  margin-left: 20px;
}

.creatorsSuggestionWrap {
  border: 0;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  margin-bottom: 20px;

  .card-header {
    padding: 10px 10px 5px;
    background-color: $white;
    font-size: 19px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: transparent;
    color: $grey;
  }

  .card-body {
    padding: 0;
  }

  .card-footer {
    padding: 0;
    background: $white;
    border-top-color: rgba(201, 201, 201, 0.4);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
    background-color: transparent;

    .btn {
      width: 100%;
      color: $theme-color;
      height: 34px;
      font-weight: 500;
      text-align: left;
    }
  }

  ul li {
    padding: 3px 10px;
    margin-bottom: 0;
    border-radius: 0 !important;
    border: 0;
    display: flex;
    align-items: center;
    background-color: transparent;

    h5 {
      font-size: 14px;
      margin-bottom: 0;
      color: $white;

      & + .btn {
        font-size: 12px;
        color: $white;
      }
    }

    h5,
    h5 + .btn {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 17px;
      max-height: 17px;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    div + .btn {
      font-size: 12px;
      color: $theme-color;
      border: 0;
      padding: 0 9px;
      height: 27px;
      line-height: 27px;
      background: $theme-color;
      color: $white;
      border-radius: 20px;
      box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
      flex-shrink: 0;
      margin-left: auto;
      font-weight: 500;
    }
  }

  .searchResultsNew {
    border-radius: 6px !important;
  }
}

.creatorSuggestionsInfo {
  @extend .searchResultsContentNew;
  flex-direction: row;
  align-items: center;
  padding: 5px;

  img {
    @extend .userImg;
    height: 36px !important;
    width: 36px !important;
    border-width: 2px !important;
    margin-right: 5px;
  }
}

.liveEventsListHome {
  @extend .creatorsSuggestionWrap;

  .card-body {
    padding-inline: 5px;
  }

  ul {
    border-radius: 8px;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;

    .list-group-item-heading {
      font-size: 14px;
      margin-top: 10px;
      padding-inline: 5px;
      margin-bottom: 2px;
      color: $black;
      text-transform: uppercase;
      font-weight: 600;
    }

    li {
      padding: 10px 3px;
      border-bottom: 1px solid $inputBorder;
      &:last-of-type {
        border-bottom: 0;
      }

      img {
        height: 50px;
        width: 50px;
        border-radius: 6px;
        padding: 2px;
        border: 1px solid $inputBorder;
        margin-right: 5px;
        background-color: $white;
      }

      h5 {
        color: $grey;
        margin-bottom: 5px;
      }

      div {
        flex-grow: 1;
        margin-right: 10px;
      }

      h6 {
        font-size: 11px;
        color: $lightGray;
        margin-bottom: 0;
        font-weight: 400;
        color: $mediumGray;
      }

      .infProfile {
        color: $grey;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 18px;
        max-height: 18px;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 3px;
        padding: 0;

        span {
          color: $theme-color !important;
          font-weight: 600;
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: 15px;

      .list-group-item-heading {
        margin-top: 12px;
      }
    }
  }
}

.ongoingEvent {
  background-color: #fef6df;
  padding-inline: 5px;

  li {
    border-color: #ffe69c !important;
  }
}

.upcomingEvent {
  background-color: rgba(var(--primary-color-rgb), 0.2) !important;
  padding-inline: 5px;

  li {
    border-color: rgba(var(--primary-color-rgb), 0.35) !important;
  }
}

.emojiBtnMsg {
  position: absolute;
  bottom: 17.5px;
  right: 40px;
  padding: 0 4px;
  font-size: 22px;
  color: $theme-color !important;
  height: 26px;
  line-height: 26px;
}

.badgeUnlocked {
  border-radius: 12px;
  border: 1px solid $theme-color;
  padding: 12px 5px;
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  text-align: center;
  position: relative;

  .fa-lock {
    display: none;
  }

  svg {
    width: 70px;
    height: 70px;
    margin-bottom: 12px;
    fill: $theme-color;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    color: $black;
    line-height: 1.25;
  }
}

.badgeLocked {
  border: 1px dashed rgba($black, 0.4);
  background-color: rgba($black, 0.6);
  box-shadow: none;

  .fa-lock {
    display: initial;
    font-size: 20px;
    color: $white;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
  }

  svg {
    fill: $mediumGray;
  }

  p {
    color: $grey;
  }
}

.achievementsSummary {
  margin-bottom: 15px;
  text-align: center;

  div {
    display: flex;
    justify-content: center;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-inline: 1px;
    padding: 4px;
    border: 1px solid rgba($black, 0.1);
    border-radius: 50%;
  }

  .active {
    svg {
      fill: $theme-color;
      border: 1px solid $theme-color;
    }
  }

  .btn {
    font-size: 14px;
    color: $grey;
    margin-top: 10px;
    padding: 0 5px;
    text-decoration: none;

    i {
      font-size: 12px;
      color: $lightGray;
      margin-left: 3px;
    }
  }
}

.passwordCriteria {
  li {
    border: 0;
    padding: 1.5px 0;

    label {
      font-weight: 400;
      color: $lightGray;
      margin-bottom: 0 !important;
      font-size: 12px !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before,
    .custom-control-input:checked ~ .custom-control-label::after {
      top: 1px;
    }
  }
}

.achievementsNotificationsWrap {
  border-radius: 10px;
  border: 1px solid $theme-color;
  padding: 15px 10px;
  background-color: $white;
  width: 95%;
  max-width: 580px;
  margin: 10px auto !important;

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadeOut 5s ease-in-out forwards;

    img {
      width: 100px; /* Initial size */
      height: 100px;
    }

    h5 {
      color: $grey;
      font-size: 18px;
      margin-block: 10px 0;
    }
  }

  .achievementsNotifications {
    visibility: hidden;
    opacity: 0;
    animation: showDiv 1s ease-in-out 5s forwards;
    height: 0;
    display: flex;

    h4 {
      font-size: 16px;
      color: $grey;
      margin-bottom: 6px;
      font-weight: 600;
      padding-right: 30px;
    }

    svg {
      height: 60px;
      width: 60px;
      min-width: 60px;
      fill: $theme-color;
    }

    p {
      font-size: 14px;
      color: $mediumGray;
      margin-bottom: 0;
      line-height: 1.35;
      display: flex;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes showDiv {
  0% {
    opacity: 0;
    visibility: visible;
    height: auto;
  }
  100% {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
}

.rankWrap {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: $theme-color;
  border-radius: 20px;
  padding: 0 7px;
  color: $white;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}

.rankInMenu {
  width: 40px;
  height: 40px;
  padding: 3px;
  position: absolute;
  bottom: -6px;
  right: -12px;
  z-index: 99;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.1);

  svg {
    width: 34px;
    height: 34px;
    fill: $theme-color;
  }
}

.singleVideoLockWrap {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.upgradeTo {
  display: block;
  text-align: center;
  margin-bottom: -5px;
  font-size: 11px;
  color: $black;
}

.customCropper {
  height: 63vh;
  background: transparent !important;
  border-radius: 2px;
}

.cropperBtnsWrap {
  text-align: center;
  margin-top: 10px;

  .btn {
    height: 40px;
    padding: 0;
    width: 40px;
    border-radius: 6px;
    margin: 0 8px;
    background: $white;
    border: 1px solid $theme-color;
    border-radius: 50%;

    &:hover {
      background: $theme-color;
      transform: none;
    }

    img {
      height: 26px;
    }
  }
}

//story view
.storyContainer {
  .storyProfile {
    position: absolute;
    top: 22px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.5%;
    z-index: 1000;

    .storyProfileUser {
      border: 2px solid $theme-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      min-width: 40px;
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      margin-bottom: 0;
    }

    p {
      font-size: 12px;
      margin-block: 4px 0;
      color: $inputBorder;
    }

    .closeModalFeed {
      position: initial;
      background-color: transparent !important;
      font-size: 20px !important;
      color: #f5f5f5 !important;
      margin-inline: 15px 3px;
      box-shadow: none !important;
    }

    .commentOptions {
      .nav-link i {
        font-size: 20px;
      }
    }
  }

  .storyFooterArea {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1.5%;
    z-index: 1000;
    background: linear-gradient(
        to bottom, 
        rgba(0, 0, 0, 0.3) 0%,  
        rgba(0, 0, 0, 0.25) 20%, 
        rgba(0, 0, 0, 0.2) 40%, 
        rgba(0, 0, 0, 0.15) 65%, 
        rgba(0, 0, 0, 0.1) 85%, 
        rgba(0, 0, 0, .05) 100% 
    );

    h5 {
      color: $white;
      font-size: 12px;
      word-wrap: break-word;
      margin-bottom: 15px;
      line-height: 1.5;
    }

    textarea  {
      padding-left: 12px !important;
    }

    .emoji-picker-react {
      margin-bottom: 10px;
      box-shadow: none;
    }
  }
}

.bb-feedback-button-icon {
  background-color: $theme-color !important;
}

.bb-feedback-button {
  bottom: 38px !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher {
  bottom: 100px !important;
}

.header-fixed {
  .bb-feedback-button {
    bottom: 20px !important;
    z-index: 997;
  }

  #onesignal-bell-container.onesignal-reset {
    z-index: 997 !important;
    
    .onesignal-bell-launcher {
      bottom: 80px !important;
    }
  }
}

.storyActionBtnWrap {  
  flex-shrink: 0;
  margin-bottom: 12px;

  .btn {
    font-size: 22px;
    color: $white;
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    padding: 0;
    will-change: transform;
    transition: all .3s;
    margin-left: 5px;

    img {
      width: 22px;
      vertical-align: -2px;
    }

    &:active {
      transform: scale(1.8);
      transition: all .3s;
    }

    .fa-heart {
      color: $theme-color;
    }
  }
}

.creatorsSuggestionMobileWrap {
  display: none;
}

.addCategoryAlert {
  margin: 4px auto 10px;
  max-width: 600px;

  i {
    font-size: 35px;
    margin-right: 10px;
    margin-top: 4px;
  }
}

.storyMsg {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: $white;
  border: 1px solid $theme-color;
  border-radius: 24px;
  padding: 0 7px;
  line-height: 20px;
  height: 22px;
  font-size: 12px;
  color: $theme-color;
}

.storyUnavailable {
  border: 1px solid rgba($black, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  border-radius: 12px;
  position: relative;
  margin-bottom: 3px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: blur(12px);
  }

  p {
    font-size: 14px;
    color: $black;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 10;
    width: 100%;
    font-weight: 500;
  }
}

// note: this project uses reactstrap version -> v8.
// media styles
@media all and (max-width: 1199.98px) {
  .productsWrap,
  .orderList {
    .col-6,
    .col-sm-6 {
      &:nth-child(3n + 1) {
        padding-right: 7px;
      }

      &:nth-child(3n + 2) {
        padding-right: 7px;
        padding-left: 7px;
      }

      &:nth-child(3n + 3) {
        padding-left: 7px;
      }
    }
  }

  .customTableWrap {
    .table tbody tr td {
      min-width: 125px;
    }
  }

  .bb-feedback-button,
  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher {
    right: 15px !important;
  }

  .creatorsSuggestionMobileWrap {
    display: block;
    max-width: 580px;
    margin: 35px auto 30px;

    h4 {
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      color: $grey;

      &+ .btn {
        color: $theme-color;
        height: 32px;
        font-weight: 500;
        padding: 0 5px;
      }
    }

    .react-multi-carousel-track {
      scroll-behavior: smooth;
    }
    
    .react-multi-carousel-item {
      &:not(:last-of-type) {
        .searchResultsNewWrap {
          padding-right: 9px;
        }
      }

      .searchResultsNew {
        height: 117px;
        margin: 0 !important;

        .creatorSuggestionsInfo div + .btn {
          font-size: 12px;
          color: $white;
          border: 0;
          padding: 0 9px;
          height: 27px;
          line-height: 27px;
          background: $theme-color;
          border-radius: 20px;
          flex-shrink: 0;
          margin-left: auto;
          font-weight: 500;
          transition: all 0.4s ease-in-out;
          overflow: hidden;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -100%;
            width: 150%;
            height: 100%;
            background: linear-gradient(120deg, transparent, rgba($white, 0.4), transparent);
            transform: skewX(-20deg);
            animation: shine 3s infinite linear;
          }

          ::after {
            content: "";
            position: absolute;
            inset: 0;
            background: radial-gradient(circle, rgba($white, 0.05) 0%, transparent 60%);
            border-radius: 50px;
            z-index: -1;
          }

          &:hover {
            box-shadow: 0 12px 40px rgba($white, 0.25);
            transform: scale(1.08);
          }
        }
      }
    }
  }
}

@keyframes shine {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}

@media all and (min-width: 992px) {
  .vaultCustomCol {
    &:nth-child(3n + 1) {
      padding-right: 8px;
    }

    &:nth-child(3n + 2) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:nth-child(3n + 3) {
      padding-left: 8px;
    }
  }

  .header-fixed .app-body {
    margin-top: 50px;
  }

  .customColPaddingLiveEvent:nth-child(odd) {
    padding-right: 10px;
  }

  .customColPaddingLiveEvent:nth-child(even) {
    padding-left: 10px;
  }

  .customPopoverBtn {
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
    border-radius: 25px;
  }

  .phoneNGenderWrap {
    display: flex;

    .form-group {
      width: 50%;

      &:not(:first-child) {
        margin-left: 10px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }

    .custom-radio {
      input,
      label {
        cursor: pointer;
      }
    }
  }

  .profileCarousel {
    width: 100% !important;
    height: 100% !important;

    .carousel {
      height: 267px;
      width: 801px;
    }

    .carousel-inner {
      height: 267px;
    }
  }

  .profileCardPublic {
    max-width: 730px;

    .profileCarousel {
      .carousel {
        width: 730px !important;
        height: 243.333px !important;

        .carousel-inner {
          height: 243.333px;
        }
      }
    }
  }
}

@media all and (max-width: 991.98px) {
  .customFilterWrap {
    flex-wrap: wrap;
  }

  .durationCustomWidth {
    max-width: 120px;
  }

  .headerNonSubscriber {
    position: absolute;
    top: 0;
    left: 0;
  }

  .liveEventStreamingWrap,
  .liveEventStreaming {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .liveEventStreaming * {
    padding: 0 !important;
    width: 100% !important;
  }

  // .commentSectionWrap-liveStream {
  //   height: 460px;
  //   position: absolute;
  //   bottom: 45px;
  //   left: 0;
  //   background-color: rgba($black, 0.1);
  //   z-index: 99;
  //   width: 100%;
  //   max-width: 100%;
  // }

  .comment-LiveStreamEvent {
    background-color: transparent;
    height: calc(100% - 235px);
  }

  .commentArea-liveStream {
    bottom: 0;
    background-color: transparent;
  }

  .liveStreamCommentWrap {
    top: 48px;
  }

  .vaultCustomCol {
    &:nth-child(odd) {
      padding-right: 8px;
    }

    &:nth-child(even) {
      padding-left: 8px;
    }
  }

  .app-body {
    margin-top: 50px;
  }

  // .comment-LiveStreamEvent .comments-Post {
  //   background-color: rgba($white, 0.09);
  //   color: #bbb;
  // }

  .mediaLibraryTab {
    padding: 0;

    .nav-link,
    .nav-link:hover,
    .nav-link:active {
      font-size: 12px;
      padding: 0.4rem 0.3rem;
      display: flex;
    }

    .nav-link {
      flex-direction: column;
      align-items: center;
    }
  }

  .reloadBtn {
    transform: translate(-50%, -28%);
  }

  .priceDetails {
    ul li {
      font-size: 12px;
    }
  }

  .feedCarousel {
    .carousel,
    .carousel-inner,
    .carousel-item {
      height: 400px;
    }
  }

  // .mediaPhotoWrap-Feed,
  // .mediaVideoWrap-Feed,
  // .mediaAudioWrap-Feed,
  // .mediaPhoto-Feed {
  //   max-height: 450px;
  // }

  .mediaLibraryMsgPg {
    .notificationDay ~ .mediaLibraryFilesMsg {
      height: 60px;
    }
  }

  .mediaLibraryMsgPg {
    .notificationDay ~ .mediaLibraryFilesMsg {
      .mediaPrice,
      .mediaHasPurchased {
        font-size: 10px;
        height: 15px;
        line-height: 16px;
      }
    }
  }

  .profileBackBtn {
    left: -25px;
  }

  .imgQualityPopover .popover {
    right: 70px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .profileCarousel {
    .carousel {
      height: 210px;
      width: 630px;
    }

    .carousel-inner {
      height: 210px;
    }
  }

  .profileCardPublic {
    max-width: 690px;

    .profileCarousel {
      .carousel {
        width: 690px !important;
        height: 230px !important;

        .carousel-inner {
          height: 230px;
        }
      }
    }
  }

  .searchResultsNew {
    height: 190px;

    .searchResultsContentNew img {
      height: 120px;
      width: 120px;
    }
  }

  .subscriptionList {
    height: 183.33px;
  }
}

@media all and (min-width: 768px) {
  .conversationDetails {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }
  }

  .noPaddingWeb {
    padding: 0;
  }

  .addPWATooltipWrap {
    width: 350px;
    bottom: unset;
    top: 28px;
    right: 65px;
    left: unset;
  }

  .addPWATooltip {
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: unset;
      top: 0;
      right: 6.8%;
      left: unset;
      box-sizing: border-box;
      border: 1em solid black;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -2px 2px 3px 0 rgba($black, 0.12);
    }
  }

  .notificationSelect {
    display: none;
  }

  .profileImgSubscription {
    width: 100px;
    height: 100px;
  }

  .influencerNameSubscription {
    a {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .paymentHistoryWrap {
    display: none;
  }

  .msgWrapWeb {
    .chatListWeb {
      width: 260px;
    }
  }

  .customPgHeight {
    height: calc(100vh - 50px);
  }

  .customWrapperFaq {
    background-color: $theme-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 250px;
    margin-bottom: 25px;

    .pgTitle {
      margin-top: 0;
      margin-bottom: 25px;
    }

    .someTipsFaq {
      margin-bottom: 0;
      // margin-top: 15px;
    }

    .faqCategoryTitle + .someTipsFaq {
      margin-top: 10px;
    }

    .pgTitle h2,
    .faqCategoryTitle,
    .someTipsFaq,
    .someTipsFaq a,
    .customBackBtn {
      color: $white !important;
    }

    .someTipsFaq a {
      text-decoration: underline;
      font-weight: 500;
    }

    .faqCategoryTitle {
      margin-top: 0;
      margin-bottom: 0;
    }

    .searchBarFaqWrap {
      width: 500px;
      margin-top: 15px;
      margin-bottom: 5px;

      .clearSearch {
        top: 13px;
      }

      input {
        background-color: $white !important;
        border-radius: 25px;

        &:focus {
          border: 0;
        }
      }
    }
  }

  .customPaddingFaq {
    padding-left: 0;
    padding-right: 0;
  }

  #accordion {
    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 18px;
    }
  }

  .app-footer {
    display: none;
  }

  .userInfo-Sidebar {
    margin-top: 10px;
    padding-bottom: 22px;

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: $grey;
      margin-bottom: 5px;
      max-width: 210px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: $mediumGray;
      max-width: 210px;
      margin-bottom: 12px;
    }
  }

  .customSidebarWrap {
    .hideSideMenu .customSidebar {
      margin-right: -250px;
      margin-left: unset;
    }

    .showSideMenu .customSidebar {
      margin-right: 0;
      margin-left: unset;
    }

    .customSidebar {
      right: 0;
      left: unset;
    }
  }

  .showFilter {
    display: none;
  }

  .liveEventCard {
    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 450px;
    }
  }

  .customBackBtnFaq {
    margin-top: -1px;
  }

  .customColPadding {
    &:nth-child(3n + 1) {
      padding-right: 7px;
    }

    &:nth-child(3n + 2) {
      padding-left: 7px;
      padding-right: 7px;
    }

    &:nth-child(3n + 3) {
      padding-left: 7px;
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1199.98px) {
  .msgWrapWeb {
    .chatListWeb {
      width: 280px;
    }
  }

  // .commentArea-liveStream {
  //   bottom: 45px;
  // }

  .comment-LiveStreamEvent {
    height: calc(100% - 235px);
  }

  .profileCarousel {
    height: 100% !important;
    width: 100% !important;
  }

  .searchResultsNew {
    height: 203.326667px;
  }

  .searchResultsContentNew ul {
    padding-left: 95px;
  }

  .subscriptionList {
    height: 196.66px;
  }
}

@media all and (max-width: 767.98px) {
  .orderDetails {
    .Cancelled {
      justify-content: center;
      .CancelledContent {
        flex: inherit;
      }
    }
  }

  .genderWrapper {
    min-height: 55px;
  }

  .customTabs {
    border-bottom: 0;
    .nav-link,
    .nav-link:hover {
      font-size: 14px;
    }
  }

  .postLocked {
    height: 300px;
  }

  .postLockedOverlay {
    h4 {
      margin-bottom: 10px;
    }

    h3 {
      margin-top: 4px;
    }
  }

  .poweredBy {
    margin: 10px auto 0;
    display: inline-block;
  }

  .loginFooter {
    .poweredBy,
    .socialLinksFooter {
      margin: 0;
    }
  }

  .noPaddingWeb {
    padding: 0 5px;
  }

  .conversationDetails {
    height: calc(100vh - 329px);
    padding-right: 12px;
  }

  .profileSummaryWrap {
    .profileSummary {
      text-align: center;
    }
  }

  .pgTitle {
    margin: 20px 0 15px;
    padding-bottom: 12px;
    border-bottom: 2px solid #e5e5e5;

    h2 {
      font-size: 18px;
    }
  }

  .customBackBtn {
    height: 22px;
    line-height: 22px;
    font-size: 15px;
    width: 22px;
  }

  .paymentWrap .card-body {
    padding: 0;
  }

  .toggleBtn {
    display: initial;
  }

  .authFormWrapper {
    width: 100%;
  }

  .installPWA {
    max-width: 150px;
    height: 36px;
    line-height: 36px;
    position: unset;
    top: unset;
    right: unset;
    margin: 30px auto 0;
    display: block;
    font-size: 14px;
  }

  .notificationPgTitle {
    padding-bottom: 5px;
    margin-top: 10px;

    h2 {
      display: none;
    }
  }

  .tab-Notification {
    display: none;
  }

  .subscriberPriceTier {
    p {
      font-size: 14px;
    }
  }

  .customFilterWrap {
    .form-group,
    .input-group {
      width: calc(50% - 10px);
    }

    .form-group:after {
      content: "";
      flex: auto;
    }
  }

  .upcomingPastEventWrap {
    margin-top: 22px;
    margin-bottom: 15px;
    padding: 0 10px;

    p {
      font-size: 14px;
    }
  }

  .searchCreators {
    margin-top: 20px;
  }

  .shoutOutWrap {
    margin-top: 5px;
  }

  .shoutOutMediaWrapCol {
    padding: 0 !important;
  }

  .customColPadding {
    &:nth-child(odd) {
      padding-right: 7px;
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-left: 7px;
      padding-right: 0;
    }
  }

  .vaultFolderRow {
    margin-left: 0;
    margin-right: 0;
  }

  .notificationContentWrap {
    padding-top: 0.25px;
  }

  .customTableWrap,
  .feedPgTitle {
    display: flex;
  }

  .userInfo-Sidebar {
    margin-top: 10px;
    padding-bottom: 22px;

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: $grey;
      margin-bottom: 5px;
      max-width: 210px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: $mediumGray;
      max-width: 210px;
      margin-bottom: 12px;
    }
  }

  .showSideMenu + .main:before {
    height: calc(100vh - 50px);
  }

  .customSidebarWrap .customSidebar {
    height: calc(100vh - 98px);
  }

  .addMessageWrap {
    height: calc(100vh - 170px);
  }

  .msgWrapWeb {
    border: 0;
    height: unset;
    height: calc(100vh - 170px);

    .chatListWeb {
      width: 100%;
      border-right: 0;

      .chatList {
        height: calc(100vh - 278px);
      }
    }

    .conversationWeb {
      .customBackBtn {
        display: initial;
      }
    }
  }

  .bottomMenu {
    border-top: 2px solid #f0f0f0;
    border-bottom: 0;
    top: unset;

    ul {
      justify-content: space-between;
    }
  }

  .app-header .navbar-brand {
    display: initial;
  }

  .unlockAccess {
    .customTopMargin {
      margin-top: 35px;
    }
  }

  .liveEventStreamingWrap,
  .liveEventStreaming {
    height: calc(100vh - 98px);
  }

  .liveStreamCommentWrap {
    bottom: 50px;
    top: 48px;
  }

  .app-body {
    margin-top: 48px;
  }

  .hideTooltipMobile {
    display: none;
  }

  .tipVault {
    height: 50px;
    width: 50px;
    padding: 0;
    border: 0;
    background-color: $theme-color !important;
    border-radius: 50%;
    position: fixed;
    right: 15px;
    bottom: 65px;
    display: initial;
    z-index: 1000;

    img {
      width: 26px;
    }
  }

  .productsWrap,
  .orderList {
    margin: 22px 0 0;

    .col-6,
    .col-sm-6 {
      &:nth-child(odd) {
        padding-right: 7px;
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-left: 7px;
        padding-right: 0;
      }
    }
  }

  .myOrderItem {
    .orderProduct {
      .orderContent {
        h3 {
          font-size: 14px;
        }

        ul li {
          font-size: 12px;
        }
      }
    }

    .orderActionButton button {
      height: 32px;
      line-height: 30px;
      font-size: 12px;
    }
  }

  .productPgTable {
    display: block;
    border: 0;
    padding: 0;
    margin-top: 8px;
  }

  .productFilterWrapper {
    margin-top: 25px;
    padding: 0;
    margin-bottom: 0;
  }

  .mediaPhotoWrap-Feed,
  .mediaAudioWrap-Feed,
  .mediaPhoto-Feed {
    max-height: 450px;

    video {
      max-height: 450px;
    }
  }

  .h-center {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .liveEventCard {
    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 350px;
    }
  }

  .tabContentMediaLibrary {
    max-height: 550px;
    overflow: auto;
  }

  .customColPadding {
    &:nth-child(odd) {
      padding-right: 7px;
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-left: 7px;
      padding-right: 0;
    }
  }

  .commentArea-liveStream {
    padding-bottom: 10px;
  }

  // custom video player
  .customVideoWrapper {
    &.floating {
      bottom: 55px;
      width: 250px !important;
      height: 140.625px !important;
      border-radius: 16px;
    }
  }
  // custom video player

  .myCallContainer {
    .ZegoRoomMobile_ZegoRoom .sKtK1LFA_jOcb1MuqFNo {
      bottom: 50px !important;
    }
  }

  .profileCarousel {
    .carousel,
    .carousel-inner,
    .carousel-item {
      max-height: 189px;
    }
  }

  .profileBackBtn {
    left: -18px;
  }

  .influencerNameSubscription {
    justify-content: space-between;
  }

  .achievementsNotificationsWrap {
    max-width: 540px;

    .achievementsNotifications {
      svg {
        height: 50px;
        width: 50px;
        min-width: 50px;
      }
    }
  }

  .rankWrap {
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 5px;
  }

  .storyCarousel {
    margin-top: 18px;
  
    .react-multi-carousel-item {
      .storyImgWrap {
        width: 68px;
        height: 68px;
  
        img {
          height: 56px;
          width: 56px;
        }
  
        .fa {
          font-size: 28px;
        }
      }
  
      p {
        width: 72px;
      }
    }
  }

  .header-fixed {
    .bb-feedback-button {
      bottom: 57px !important;
    }

    #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher {
      bottom: 105px !important;
    }
  }

  .bb-feedback-button-icon {
    width: 36px !important;
    height: 36px !important;
  
    .bb-logo-logo {
      height: 22px !important;
      width: 22px !important;
      top: 7px !important;
      left: 7px !important;
    }
  }

  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-button {
    height: 36px !important;
    width: 36px !important;
  }

  .creatorsSuggestionMobileWrap {
    .react-multi-carousel-item {
      .searchResultsNew {
        height: 97px;
      }
    }

    .react-multiple-carousel__arrow {
      min-width: 24px;
      min-height: 24px;
      height: 24px;
      bottom: 5px;

      &::before {
        font-size: 14px;
        line-height: 23px;
      }
    }

    .react-multiple-carousel__arrow--right {
      right: calc(1.5% + 1px);
    }

    .react-multiple-carousel__arrow--left {
      left: calc(1.5% + 1px);
    }
  }

  .storySkeleton {
    width: 68px !important;
    height: 68px !important;
  }
  
  .addCategoryAlert {
    max-width: 540px;
    margin-bottom: 4px;
  }
}

@media all and (min-width: 576px) and (max-width: 767.98px) {
  .profileCarousel {
    .carousel {
      height: 170px;
      width: 510px;
    }

    .carousel-inner {
      height: 170px;
    }
  }

  .searchResultsNew {
    height: 170px;

    .searchResultsContentNew img {
      height: 100px;
      width: 100px;
    }
  }

  .searchResultsContentNew ul {
    padding-left: 75px;
  }

  .subscriptionList {
    height: 163.33px;
  }
}

@media all and (min-width: 576px) {
  .searchResultsNewWrap {
    height: 100% !important;
    width: 100% !important;
  }
}

@media all and (max-width: 575.98px) {
  .loginFooter {
    flex-direction: column;
    align-items: center;

    .socialLinksFooter-Login {
      margin-bottom: 8px;
    }
  }

  .subscriberPriceTier div {
    margin-right: 15px;
  }

  .noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  .pgPadding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .noMargin {
    margin-left: 0;
    margin-right: 0;
  }

  .liveEventDetailsWrap,
  .shadowOverlayImg,
  .mediaPhoto-Feed,
  .mediaPhotoWrap-Feed,
  .mediaVideoWrap-Feed,
  .cardFeed,
  .cancelledEventWrap,
  .customTabContent-Notifications,
  .chatList,
  .commentSection,
  .mediaVideoWrap-Feed video,
  .mediaPhotoWrap-Feed video,
  table,
  .singleVideoLockWrap {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .vaultFolderWrap {
    .shadowOverlayImg {
      border-radius: 12px !important;
    }
  }

  .ppvPreviewStatsWrap,
  .liveEventDetailsWrap {
    padding: 0;
    margin-bottom: 10px;
  }

  .commentSection {
    border-bottom: 1px solid rgba($black, 0.08);
    margin-bottom: 0;
    padding-bottom: 2px;
  }

  .cardFeed {
    margin-top: 8px;

    .card-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    .postTime-Feed {
      margin-left: 6px;
    }
  }

  .attendingStatus,
  .rsvpResponse,
  .paymentLiveStream {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
  }

  .selectChat,
  .customTabs,
  .creatorCategory {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sectionTitle {
    margin: 35px 15px 10px;

    h4 {
      font-size: 20px;
    }
  }

  .subscriptionsCard {
    margin-bottom: 25px;

    .card-body,
    .card-footer {
      padding: 0;
    }

    .card-footer {
      padding-top: 12px;
    }
  }

  .customPaddingMob,
  .cardFeed .card-body p {
    width: 100%;
    padding: 0 10px !important;
  }

  .customPgHeight {
    background: $white;
  }

  .mediaPhoto-Feed {
    border-left: 0;
    border-right: 0;
  }

  .tabsWrap-InfluencerDetails {
    .customTabs {
      padding: 0;
    }
  }

  .customTabContent .tab-pane {
    padding: 2px 0;
  }

  .customTabs-Notifications {
    margin-top: 10px;
    padding: 0;
  }

  .searchIcon {
    left: 23px;
  }

  .postLockedOverlay {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }

  .editSubscription {
    flex-direction: column;

    > div {
      width: 100%;
    }

    .sectionTitle {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .passwordInput {
    padding-right: 35px;
  }

  .customBorderVault {
    margin: 20px 0 30px;
  }

  .modalFeed_closeIcon {
    margin-top: 0;
  }

  .customBreadcrumb {
    .breadcrumb li {
      font-size: 14px;
    }

    .breadcrumb li a {
      font-size: 18px;
    }
  }

  .signUpFooter {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  // .pagination {
  //   padding-right: 15px;
  // }

  .mediaAudioWrap-Feed {
    border-radius: 0;
  }

  .liveStreamCommentWrap {
    top: unset;
    bottom: 50px;
    width: 100%;
    height: 400px;

    &.show + .liveStreamCommentToggle {
      right: 15px;
    }
  }

  .commentSectionWrap-liveStream {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  .DateRangePicker_picker {
    top: 49px !important;
  }

  .DateInput_fang {
    top: 38px !important;
  }

  .customPadding {
    padding: 0;
  }

  .subscriptionDateTxt {
    font-size: 11px;
  }

  .currentPlan {
    font-size: 12px;

    img {
      margin-left: 4px;
      margin-right: 3px;
      height: 20px;
    }
  }

  .customModalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .authPgFormWrap {
    border: 0;
    padding: 0;
  }

  .loginWrapper {
    margin-top: 0;
  }

  .creatorInfoVault {
    background: #fafafa;
  }

  .customMarginMob {
    margin-left: 10px;
    margin-right: 10px;
  }

  .ppvDetailsWrap {
    padding: 0 10px;
  }

  .shoutOutWrap {
    padding: 0;
  }

  .vaultFolderDetailsWrap {
    padding: 0;
  }

  .savedCardInfo {
    background: #fafafa;
    border-radius: 15px;
    padding: 0 12px;

    li {
      .btn-ghost-danger {
        margin-top: 0;
        font-size: 14px;
      }

      p,
      .makeDefault {
        font-size: 12px;
      }
    }
  }

  .sectionHeader {
    margin-bottom: 12px;
  }

  .coverImgWrap {
    height: 240px;
    min-height: 240px;
  }

  .influencerDetailsWrap {
    .img100by100 {
      margin-top: -50px;
    }
  }

  .notificationContentWrap {
    padding: 0;
  }

  .vaultCustomCol {
    &:nth-child(odd) {
      padding-left: 0;
      padding-right: 6px;
    }

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 6px;
    }
  }

  .joinLiveEventWrap {
    border-radius: 15px;
    padding: 30px 10px;
    text-align: center;
    margin: 15px auto;
  }

  .mediaPhotoWrap-Feed {
    .groupMediaWrap {
      height: 200px;
    }
  }

  .customTabContentInfluencerDetails {
    padding: 0;
  }

  .feedCarousel {
    border-radius: 0;

    .carousel,
    .carousel-inner,
    .carousel-item {
      border-radius: 0;
      height: 350px;
    }

    video,
    .carouselImg,
    .videoPreview {
      border-radius: 0;
    }

    .audioWrapperFeedCarousel {
      border: 0;
      border-top: 1px solid rgba($black, 0.025);
      border-bottom: 1px solid rgba($black, 0.025);
      border-radius: 0;
    }
  }

  .profileBackBtn {
    top: 12px;
  }

  .influencerCard-Search {
    padding-left: 10px;
    padding-right: 10px;
  }

  .searchCreators {
    padding-left: 10px;
    padding-right: 10px;

    .searchIcon {
      left: 20px;
    }

    .clearSearch {
      right: 20px;
    }
  }

  .authFormWrapper {
    min-height: calc(100vh - 55px);
  }

  .typeMsgWrap,
  .commentArea-liveStream {
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding: 8px;
    background: $white;
    z-index: 99;
  }

  .commentArea-liveStream {
    background-color: transparent;
    padding: 8px 0 10px;
    position: unset;
  }

  .comment-LiveStreamEvent {
    height: calc(100% - 220px);
    padding-bottom: 10px;
  }

  .conversationDetails {
    height: 100%;
    padding-bottom: 8px;
  }

  .filterBtnInfluencerDetails {
    margin-right: 10px;
  }

  .footerFeed {
    padding-left: 5px;
    padding-right: 5px;

    .btn {
      &:first-child {
        padding-left: 6px;
      }
    }

    span {
      &:first-child {
        margin-left: 6px;
      }
    }
  }

  .profileCarousel {
    .carousel,
    .carousel-inner,
    .carousel-item {
      max-height: 212px;
    }
  }

  .goalDataWrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mediaBtn {
    .videoIcon {
      height: 18px;
    }

    .audioIcon {
      height: 21px;
    }
  }

  .addMessageWrap {
    height: calc(100vh - 194px);
  }

  .modulesInfoWrap {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    ul li {
      padding-left: 15px;
      padding-right: 0;

      &:before {
        left: 0;
      }
    }

    .subModuleCollapseHead,
    .subModuleCollapseBody {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .tabsCourses {
    padding-left: 0;
    padding-right: 0;

    .customTabs {
      padding-left: 0;
      padding-right: 0;
    }

    .nav-link {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .wishlistItem {
    padding: 12px 0;
    border: 0;
    border-bottom: 1px solid rgba($black, 0.08);
    border-radius: 0;
    margin-bottom: 0;

    .remove {
      right: 5px;
      top: unset;
      bottom: 15px;
    }

    .productImgWrap {
      width: 110px;
      height: 100px;
    }

    .productInfoCart {
      padding-left: 10px;

      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .price {
        margin-bottom: 5px;

        .salePrice {
          font-size: 14px;
        }
      }

      .detail {
        li {
          p,
          span {
            font-size: 12px;
          }

          p {
            &:first-child {
              min-width: 55px;

              &::after {
                right: 8px;
              }
            }
          }

          &:not(:first-child) {
            .rating {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .filesSelectedTxt {
    font-size: 12px;
    width: 115px;
    flex-wrap: wrap;
  }

  .productCard {
    border-radius: 0;

    .card-body {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .addressLayout {
    border: 0;
    border-bottom: 1px solid rgba($black, 0.1);
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }

  .paymentHeader {
    margin-bottom: 5px;

    h2 {
      font-size: 16px;
    }
  }

  .myOrderItem {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid rgba($black, 0.1);
    border-radius: 0;
    margin-bottom: 0;

    .orderProduct {
      .orderContent {
        padding-left: 10px;

        ul {
          flex-direction: column;

          li {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  .orderTrack {
    ul {
      height: 300px;
      flex-direction: column-reverse;
      li {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        span {
          display: block;
          &.dot {
            margin-bottom: -5px;
            margin-left: -5px;
          }
          &:not(.dot) {
            margin-left: 10px;
            margin-bottom: -10px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: rgba($mediumGray, 0.2);
          z-index: -1;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1px;
          height: 0;
          background-color: green;
          z-index: -1;
        }
        &:nth-child(1) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 1s 1 forwards;
            }
            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 1s 1 forwards;
              }
              &.status {
                animation: textcolor 0.8s ease-in-out 1s 1 forwards;
              }
            }
          }
        }
        &:nth-child(2) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 2s 1 forwards;
            }
            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 2s 1 forwards;
              }
              &.status {
                animation: textcolor 0.8s ease-in-out 2s 1 forwards;
              }
            }
          }
        }
        &:nth-child(3) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 3s 1 forwards;
            }
            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 3s 1 forwards;
              }
              &.status {
                animation: textcolor 0.8s ease-in-out 3s 1 forwards;
              }
            }
          }
        }
        &:nth-child(4) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 4s 1 forwards;
            }
            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 4s 1 forwards;
              }
              &.status {
                animation: textcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }
        &:last-child {
          flex: 0;
          &::after {
            display: none;
          }
          &::before {
            display: none;
          }
          &.cancelled {
            span {
              &.dot {
                animation: CanceldotColor 0.8s ease-in-out 4s 1 forwards;
              }
              &.status {
                animation: Canceltextcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }
      }
    }
    @keyframes Mobileprocess {
      from {
        height: 0;
      }
      to {
        height: 100%;
      }
    }
  }

  .customFilterWrap {
    margin-top: -10px;
    border-bottom: 1px solid rgba($black, 0.08);
    border-radius: 0;

    .dateRange {
      width: 100%;
    }
  }

  .liveEventCard {
    margin-top: 5px;
  }

  .cardFeed {
    & + .commentSection {
      padding-left: 10px;
      padding-right: 10px;
    }

    .card-body {
      .bundles-Tier,
      .offers-Tier {
        p {
          padding: 0 !important;
        }
      }
    }
  }

  .tipAmtWrap .btn {
    padding: 0 11px;
  }

  .portraitVideoWrap,
  .landscapeVideoWrap,
  .squareVideoWrap {
    .videoPreview,
    video {
      border-radius: 0;
    }

    // &:focus {
    //   .videoQuality {
    //     display: initial;
    //   }
    // }
  }

  .portraitVideoWrap {
    padding-top: 177.77%;
  }

  .mediaPhotoWrap-Feed,
  .mediaAudioWrap-Feed,
  .mediaPhoto-Feed {
    max-height: 400px;

    video {
      max-height: 400px;
    }
  }

  .postLockedOverlay {
    .iconLock {
      height: 51px;
    }
  }

  .pollOptionWrap,
  .totalVotes {
    padding-left: 10px;
    padding-right: 10px;
  }

  .restartSubscription {
    margin-bottom: 0;
  }

  .vaultFolderWrap {
    .card-body,
    .folderCoverPic {
      height: 180px;
    }

    video {
      max-height: 180px;
    }
  }

  .vaultMediaThumbWrap {
    height: 180px;

    video,
    .vaultMediaThumb {
      height: 180px;
    }
  }

  .mediaVideoWrap-Feed {
    max-height: unset;

    video,
    .customVideoWrapper {
      max-height: unset;
    }
  }

  .tabContentMediaLibrary {
    max-height: 350px;
  }

  .mediaLibraryMsgPg {
    .notificationDay ~ .mediaLibraryFilesMsg {
      width: calc(25% - 6px);
    }
  }

  .msgPreviewWrap {
    margin-top: 5px;
  }

  // custom video player
  .customVideoWrapper {
    &.nowPlaying {
      border-radius: 0;
    }

    .videoVolume {
      display: none;
    }

    .videoControlsWrap {
      border-radius: 0;
    }

    .videoControls .btn {
      margin: 0 3px;
    }

    .playPauseVideo {
      height: 40px;
      width: 40px;
      font-size: 40px;

      img {
        height: 34px;
      }
    }
  }
  // custom video player

  .newMessageWrap {
    padding-top: 5px;
  }

  .profileCard {
    margin-top: 0;
  }

  .notificationPgTitle {
    margin-bottom: 0;
  }

  .renewSubscriptionCard {
    padding: 8px;
  }

  .searchResultsNewWrap {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .searchResultsNew {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0;
    height: 100%;
    width: 100%;

    ul {
      padding-left: 12px;

      li {
        font-size: 12px;

        &:first-child {
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }

  .searchResultsTitle {
    padding: 0 10px;
  }

  .profileCarousel {
    .carousel {
      height: 100%;
      width: 100%;
    }

    .carousel-inner {
      height: 100%;
    }
  }

  .searchResultsContentNew {
    padding: 8px;
    font-size: 12px;

    .btn {
      font-size: 12px;
    }

    img {
      height: 70px;
      width: 70px;
    }
  }

  .sharedPostHighLighted .cardFeed {
    max-width: 97%;
    border-radius: 12px !important;
    box-shadow: 0px 0px 20px -4px rgba(var(--primary-color-rgb), 0.7);
    // border-top: 1px solid rgba(var(--primary-color-rgb),0.7);
    // border-bottom: 1px solid rgba(var(--primary-color-rgb),0.7);
  }

  .imgQualityPopover .popover {
    right: 60px !important;
  }

  .badgeUnlocked {
    margin-block: 10px;
    padding-block: 10px;

    svg {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 12px;
    }
  }

  .storyCarousel {
    padding-inline: 10px;
  }

  .bb-feedback-button {
    bottom: 20px !important;
  }

  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher {
    bottom: 70px !important;
  }

  .creatorsSuggestionMobileWrap {
    margin-bottom: 25px;
    padding-inline: 10px;

    .react-multi-carousel-item {
      .searchResultsNewWrap {
        margin-block: 0;
      }

      .searchResultsNew {
        height: 100%;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .msgWrapWeb {
    .chatListWeb {
      width: 380px;
    }
  }

  .productsWrap,
  .orderList {
    .col-6 {
      &:nth-child(4n + 1) {
        padding-right: 7px;
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        padding-right: 7px;
        padding-left: 7px;
      }

      &:nth-child(4n + 4) {
        padding-left: 7px;
        padding-right: 15px;
      }
    }
  }

  .liveEventCard {
    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 400px;
    }
  }

  .customColPadding {
    &:nth-child(4n + 1) {
      padding-right: 7px;
      padding-left: 15px;
    }

    &:nth-child(4n + 2) {
      padding-left: 7px;
      padding-right: 7px;
    }

    &:nth-child(4n + 3) {
      padding-right: 7px;
      padding-left: 7px;
    }

    &:nth-child(4n + 0) {
      padding-left: 7px;
      padding-right: 15px;
    }
  }

  .searchResultsNew {
    height: 211.666667px;
  }

  .creatorSuggestions {
    height: 91.6666px;
    margin-block: 0 !important;
  }
}

// to prevent the user from taking a pdf
@media print {
  .header-fixed {
    display: none;
  }
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

.checkEmail {
  height: 70px;
  display: block;
  margin: -10px auto 0;
}

#otpless {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.btn-snapchat {
  background: #fffc00;

  &:hover {
    background: #eeeb00;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.4) !important;
  }

  i {
    color: $white;
    background-color: rgba($black, 0.1);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #eeeb00;
    border-color: #eeeb00;
  }
}

.show>.btn-snapchat.dropdown-toggle {
  background-color: #eeeb00;
  border-color: #eeeb00;
}