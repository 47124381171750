.fab-container {
  bottom: 4vh !important;
  right: 4vw !important;
  z-index: 1;

  button {
    background-color: white !important;
    color: #000 !important;
    font-size: 20px;

    i {
      font-size: 18px;
    }
  }
}

#language-switcher-container {
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0rem 0rem !important;
    background-color: rgb(0, 0, 0) !important;
    cursor: unset !important;
  }

  .btn-lg,
  .btn-group-lg > .btn div {
    padding: 0rem 0rem !important;
    width: 100%;
    cursor: pointer !important;
    height: 100%;
  }
}
